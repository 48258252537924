import generalMiddleware, { axiosBaseInstance } from './middleware/middleware';
import { Response } from './middleware/format-response';
import { RegisterUser, LoginUser, LoginResponse } from '../modals/modals';
import axios from 'axios';
import { config } from 'process';

export const registerUser = (data: RegisterUser): Promise<Response<string>> =>
  generalMiddleware.request({
    url: '/Account/Register',
    method: 'POST',
    data: data,
  });
export const registerUserNew = (
  data: RegisterUser
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: '/Account/RegisterByInvite',
    method: 'POST',
    data: data,
  });

export const getUserDataEmail = (email: string): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/User/GetUserByEmail/${email}/`,
    method: 'GET',
  });

export const updateUserData = (data: any): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/User`,
    method: 'PUT',
    data: data,
  });
export const AddUserImage = (data: any): Promise<Response<any>> => {
  const formData = new FormData();
  formData.append('', data.imageData);
  return generalMiddleware.request({
    url: `User/SaveImage?userId=${data.id}`,
    method: 'Post',
    data: formData,
  });
};
export const AddEnterpriseImage = (data: any): Promise<Response<any>> => {
  const formData = new FormData();
  formData.append('', data.imageData);
  return generalMiddleware.request({
    url: `Enterprise/SaveImage?enterpriseId=${data.id}`,
    method: 'Post',
    data: formData,
  });
};

export const getUserByID = (id: any): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/User/${id}`,
    method: 'GET',
  });

export const getUsersByEnterpriseID = (
  id: any,
  text: string
): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/User/GetUserByEnterpriseId?Enterprise_ID=${id}&searchText=${text}`,
    method: 'GET',
  });
export const searchUser = (
  id: any,
  campaignId: number,
  text: string
): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/CampaignLine?enterpriseId=${id}&campaignId=${campaignId}&searchText=${text}`,
    method: 'GET',
  });

export const searchComment = (id: any, text: string): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Campaign?Enterprise_ID=${id}&SearchText=${text}&isComment=true`,
    method: 'GET',
  });
export const searchJob = (id: any, text: string): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Campaign?Enterprise_ID=${id}&SearchText=${text}`,
    method: 'GET',
  });

export const loginUser = (data: LoginUser): any => {
  const { email, password } = data;
  const requestData = `username=${email}&password=${password}&grant_type=password`;

  // return axios.post(`${BASE_URL_PREFIX}/token`, requestData, {
  //   headers: {
  //     // 'Content-Type': 'text/plain',
  //     "Access-Control-Allow-Origin": "*",
  //     "Content-Type": "text/plain",
  //   },
  // });

  return axiosBaseInstance.request({
    url: '/token',
    method: 'POST',
    data: requestData,
    headers: {
      'Content-Type': 'text/plain',
    },
  });
};
// export const UserExistInEnterprise = (email: any): any => {

//   return axiosBaseInstance.request({
//     url: `/User/GetUserByEmail/${email}/`,
//     method: 'GET',
//     headers: {
//       'Content-Type': 'text/plain',
//       'Access-Control-Allow-Origin': '*',
//     },
//   });
// };

export const sendOTP = (email: string): any => {
  return axiosBaseInstance.request({
    url: `api/OTP/sendOTP?email=${email}`,
    method: 'POST',
  });
};

export const verifyOTP = (otp: string): any => {
  return axiosBaseInstance.request({
    url: `api/OTP/verifyOTP?otp=${otp}`,
    method: 'POST',
  });
};

export const setNewPassword = (data: any): any => {
  return axiosBaseInstance.request({
    url: `api/Account/setNewPassword`,
    method: 'POST',
    data: data,
  });
};
