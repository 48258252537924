import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  registerUser,
  loginUser,
  getUserDataEmail,
  updateUserData,
  getUserByID,
  getUsersByEnterpriseID,
  AddUserImage,
  AddEnterpriseImage,
  searchUser,
  searchJob,
  registerUserNew,
  searchComment,
  sendOTP,
  verifyOTP,
  setNewPassword,
} from '../../api/auth';
import { LoginUser, RegisterUser } from '../../modals/modals';
import { toastFailure, toastSuccess } from '../../utils/toast';

export const signUpUser = createAsyncThunk(
  'user/register',
  async (data: RegisterUser) => {
    return {
      response: await registerUser(data),
    };
  }
);
export const signUpUserForInvite = createAsyncThunk(
  'user/registerNew',
  async (data: RegisterUser) => {
    return {
      response: await registerUserNew(data),
    };
  }
);

export const logInUser = createAsyncThunk(
  'user/login',
  async (data: LoginUser) => {
    return {
      response: await loginUser(data),
    };
  }
);
export const getUserDataEmailSlice = createAsyncThunk(
  'user/getuser',
  async (data: string) => {
    return {
      response: await getUserDataEmail(data),
    };
  }
);
export const updateUserDataSlice = createAsyncThunk(
  'user/updateuser',
  async (data: any) => {
    return {
      response: await updateUserData(data),
    };
  }
);

export const getUserByIDSlice = createAsyncThunk(
  'user/getuserbyid',
  async (id: any) => {
    return {
      response: await getUserByID(id),
    };
  }
);
export const getUsersByEnterpriseIDSlice = createAsyncThunk(
  'user/getusersbyenterpriseid',
  async (obj: any) => {
    return {
      response: await getUsersByEnterpriseID(obj.enterpriseId, obj.searchText),
    };
  }
);
export const getUsersByEnterpriseIDSearch = createAsyncThunk(
  'user/getusersbysearch',
  async (obj: any) => {
    return {
      response: await getUsersByEnterpriseID(obj.enterpriseId, obj.searchText),
    };
  }
);
export const searchUsers = createAsyncThunk('user/search', async (obj: any) => {
  return {
    response: await searchUser(
      obj.enterpriseId,
      obj.campaignId,
      obj.searchText
    ),
  };
});
export const searchCommnets = createAsyncThunk(
  'commentt/search',
  async (obj: any) => {
    return {
      response: await searchComment(obj.enterpriseId, obj.searchText),
    };
  }
);
export const searchJobs = createAsyncThunk('job/search', async (obj: any) => {
  return {
    response: await searchJob(obj.enterpriseId, obj.searchText),
  };
});

export const AddUsersImage = createAsyncThunk(
  'user/addimage',
  async (formData: any) => {
    return {
      response: await AddUserImage(formData),
    };
  }
);
export const AddEnterprisesImage = createAsyncThunk(
  'enterprise/addimage',
  async (object: any) => {
    return {
      response: await AddEnterpriseImage(object),
    };
  }
);

export const SendOTP = createAsyncThunk(
  'api/OTP/sendOTP',
  async (email: string) => {
    return {
      response: await sendOTP(email),
    };
  }
);

export const VerifyOTP = createAsyncThunk(
  'api/OTP/verifyOTP',
  async (otp: string) => {
    return {
      response: await verifyOTP(otp),
    };
  }
);

export const SetNewPassword = createAsyncThunk(
  'api/Account/setNewPassword',
  async (data: any) => {
    return {
      response: await setNewPassword(data),
    };
  }
);

interface UserState {
  token: string;
  userData: any;
  userById: any;
  usersByEnterpriseId: any;
}

const initialState: UserState = {
  token: '',
  userData: null,
  userById: null,
  usersByEnterpriseId: null,
};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUserReducer: (state, action) => {
      state.token = action.payload.token;
    },
    initialUserReducer: (state, action) => {
      if (action.payload != null) {
        state.userData = null;
        state.userById = null;
        state.usersByEnterpriseId = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUpUser.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        toastSuccess('Register successfully.');
      } else {
        if (!action.payload.response.successful) {
          const { details, message } = action.payload.response;
          if (details) {
            Object.keys(details).forEach((key) => {
              const fieldErrors = details[key];

              if (fieldErrors[0].toLowerCase().includes('already taken')) {
                toastFailure(
                  'User already exists. Please use a different email address.'
                );
              }
            });
          } else {
            toastFailure(message || 'Failed to register.');
          }
        }
      }
    });
    builder.addCase(signUpUserForInvite.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        // toastSuccess('Register successfully.');
      } else {
        if (!action.payload.response.successful) {
          toastFailure('Failed to register.');
        }
      }
    });
    builder.addCase(getUserDataEmailSlice.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        state.userData = action.payload.response.data;
      }
    });
    builder.addCase(getUserByIDSlice.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        state.userById = action.payload.response.data;
      }
    });
    builder.addCase(getUsersByEnterpriseIDSlice.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        console.log(
          'action.payload.response.data',
          action.payload.response.data
        );
        state.usersByEnterpriseId = action.payload.response.data;
      }
    });
    builder.addCase(updateUserDataSlice.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        // state.userData = action.payload.response.data

        toastSuccess(action.payload.response.data);
      }
    });
    builder.addCase(AddUsersImage.fulfilled, (state, action) => {
      // console.log(action.payload, 'action.payload');
    });
    builder.addCase(AddEnterprisesImage.fulfilled, (state, action) => {
      // console.log(action.payload, 'action.payload');
    });

    // login user
    // builder.addCase(logInUser.fulfilled, (state, action) => {
    //     console.log(action.payload.response.statusText == "OK");

    //     // if (action.payload.response.successful) {
    //     //     toastSuccess("LogIn successfully.");
    //     // } else {
    //     //     if (!action.payload.response.successful) {
    //     //         // toastFailure("Failed to log in.");
    //     //     }
    //     // }
    // })
  },
});

export const { loginUserReducer, initialUserReducer } = UserSlice.actions;

export default UserSlice.reducer;
