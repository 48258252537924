import React, { lazy, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toastSuccess } from '../utils/toast';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Routes, Route, Navigate } from 'react-router-dom';
import { CircleLoader } from 'react-spinners';

import { Colors } from '../theme/colors';

import ProtectedRoutes from '../components/Utils/ProtectedRoutes/ProtectedRoutes';
import InviteUserLinkPage from '../screens/InviteUser/InviteUesrLinkPage';
import ForgotPassword from '../screens/forgotpassword/forgotpassword';
import EditEmployer from '../screens/ClientsList/EditEmployer/EditEmployer';
import CreateEmployer from '../screens/ClientsList/CreateEmployer/CreateEmployer';
import CampaignForm from '../screens/InstantJob/CampaignForm/CampaignForm';
import InstantInvite from '../screens/InstantJob/InstantInvite/InstantInvite';

// routes screen

// const Home = React.lazy(() => import('../screens/Home/Home'));
const JobDashboard = React.lazy(() => import('../screens/Home/JobDashboard'));
const CandidatesLists = lazy(
  () => import('../screens/Candidates/CandidatesLists')
);
const Templates = lazy(() => import('../screens/Templates/Templates'));
const CustomForm = lazy(() => import('../screens/CustomForm/Customform'));
const LoginScreen = lazy(() => import('../screens/Login/LoginScreen'));
const RegistrationScreen = lazy(
  () => import('../screens/Registration/RegistrationScreen')
);
const DashboardNew = lazy(() => import('../components/dashboard/DashboardNew'));
const CampainListNew = lazy(() => import('../screens/campaign/CampainListNew'));
const InstantJob = lazy(() => import('../screens/InstantJob/InstantJob'));
const CreateCampaignNew = lazy(
  () => import('../screens/campaign/CreateCampaignNew')
);
const CampaignNameAndTitle = lazy(
  () => import('../screens/campaign/campaignNameAndTitle')
);
const GenerateQuestoinAndVideo = lazy(
  () => import('../screens/campaign/GenerateQuesAndVideo')
);
// const EditInfo = lazy(() => import('../screens/CompanyInformation/editInfo'));
///////////////////REMOVED FOR CLIENT LIST///////////////////////////////////////
const CompanyList = lazy(
  () => import('../screens/CompanyInformation/CompanyList')
);

const ClientsList = lazy(() => import('../screens/ClientsList/ClientsList'));
const CandidateInfo = lazy(() => import('../screens/Candidates/CandidateInfo'));
const Users = lazy(() => import('../screens/Users/Users'));
const PrivacyPolicy = lazy(() => import('../screens/Privacy/PrivacyPolicy'));
const TermsOfServices = lazy(
  () => import('../screens/Privacy/TermsOfServices')
);
const SelectPackage = lazy(
  () => import('../screens/Subscription/SelectPackages')
);
const BillingMethod = lazy(
  () => import('../screens/Subscription/BillingMethods')
);
const OnboardingMain = lazy(
  () => import('../screens/Onboarding/OnboardingMain')
);

function App() {
  const handleClickCheck = () => {
    toastSuccess('Deleted instruction set');
  };

  return (
    <div
      className='App'
      style={{
        background: Colors.gray_E5,
        position: 'relative',
        height: '100vh',
      }}
    >
      <Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              background: 'white',
            }}
          >
            <CircleLoader color='#AA19D5' />
          </div>
        }
      >
        <DndProvider backend={HTML5Backend}>
          <Routes>
            <Route path={'/login'} element={<LoginScreen />} />
            <Route path={'/registration'} element={<RegistrationScreen />} />
            <Route
              path={'/registration/:code'}
              element={<RegistrationScreen />}
            />
            <Route
              path={'/invite/:id/:eId/:statusId'}
              element={<InviteUserLinkPage />}
            />
            <Route
              path={'/'}
              element={
                <ProtectedRoutes redirectTo='/login'>
                  <DashboardNew />
                </ProtectedRoutes>
              }
            >
              <Route
                path={'dashboard'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    {/* <Home /> */}
                    <JobDashboard />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'/'}
                element={<Navigate to='/dashboard' replace />}
              />
              <Route
                path={'/'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    {/* <Home /> */}
                    <JobDashboard />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'campaign/create'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <CreateCampaignNew pageType='create' />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'campaign/title'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <CampaignNameAndTitle />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'campaign/instant'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <InstantJob pageType='instant' />
                    {/* <CampaignForm pageType='create' /> */}
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'campaign/instant/create'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <CampaignForm pageType='create' />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'campaign/instant/invite'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <InstantInvite />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'campaign/generate'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <GenerateQuestoinAndVideo />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'campaign/edit'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <CreateCampaignNew pageType='edit' />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'campaign'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <CampainListNew />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'templates'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <Templates />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'custom-form'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <CustomForm />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'company'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <CompanyList />
                  </ProtectedRoutes>
                }
              />

              <Route
                path={'clients'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <ClientsList />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'/clients/edit'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <EditEmployer />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'/clients/create'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <CreateEmployer />
                  </ProtectedRoutes>
                }
              />
              {/* <Route
                path={'company/edit'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <EditInfo />
                  </ProtectedRoutes>
                }
              /> */}
              <Route
                path={'candidates'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <CandidatesLists />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'/candidates/info'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <CandidateInfo />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'/user'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <Users />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'/privacy-policy'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <PrivacyPolicy />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'/terms-of-condition'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <TermsOfServices />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'/packages'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <SelectPackage />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={'/billing-method'}
                element={
                  <ProtectedRoutes redirectTo='/login'>
                    <BillingMethod />
                  </ProtectedRoutes>
                }
              />
            </Route>
            <Route
              path={'/onboarding'}
              element={
                <ProtectedRoutes redirectTo='/login'>
                  <OnboardingMain />
                </ProtectedRoutes>
              }
            />
            <Route path={'/forgotpassword'} element={<ForgotPassword />} />
          </Routes>
        </DndProvider>
      </Suspense>

      <ToastContainer />
    </div>
  );
}

export default App;
