import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import formatResponse from './format-response';
import { BASE_URL, BASE_URL_PREFIX } from '../../utils/config';

const token = () => localStorage.getItem('token') || '';

const addAuthorizationHeader = (config: AxiosRequestConfig) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token()}`,
    'Access-Control-Allow-Origin': '*',
    'Content-Type': config.headers?.['Content-Type'] || 'application/json',
  };
  return config;
};

const generalMiddleware = axios.create({
  baseURL: BASE_URL,
});

const axiosInstanceMiddleware = axios.create({
  baseURL: BASE_URL_PREFIX,
});

generalMiddleware.interceptors.request.use(addAuthorizationHeader);
axiosInstanceMiddleware.interceptors.request.use(addAuthorizationHeader);

export const axiosBaseInstance = formatResponse(axiosInstanceMiddleware);
export default formatResponse(generalMiddleware);
