import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
  TextField,
  Card,
  CardContent,
  CircularProgress,
  Menu,
  List,
} from '@mui/material';

import { Add, Check, Close } from '@mui/icons-material';
import React, {
  useEffect,
  useState,
  useRef,
  Fragment,
  useCallback,
} from 'react';
import CommonLists from '../Common/Lists';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../images/icons/backIcon.svg';
import { Colors } from '../../../theme/colors';
import { toastFailure, toastSuccess } from '../../../utils/toast';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  createCampaigns,
  createJobSettings,
  getJobLocationRegionCampaign,
  getJobMainCategoriesCampaign,
  getJobSubCategoriesCampaign,
  updateCampaigns,
} from '../../../redux/campaigns/campaigns-slice';
import { Urls } from '../../../utils/urls';
import moment from 'moment';
import {
  clearQuestion,
  createAIJobDescriptionAPI,
  createAIQuestionAPI,
  createQuestions,
  deleteQuestionReducer,
  deleteQuestions,
  fetchQuestions,
  updateHtmlDes,
  updateJobDesc,
  updateQuestions,
  updateQuestionsListReducer,
} from '../../../redux/questions/question-slice';
import {
  fetchAllEnterprises,
  fetchEnterprisesById,
} from '../../../redux/enterprise/enterprise-slice';
import DateSelectorNew from '../../../components/Utils/DateSelector/DateSelectorNew';
import {
  formatDateStringForPayoad,
  formatDateToString,
  formatStringToDate,
} from '../../../utils/dateFormatter';

import AddIcon from '@mui/icons-material/Add';
import SelectboxforJobCategory from '../../../components/Utils/Selectbox/SelectboxforJobCategory';
import ReactFlagsSelect from 'react-flags-select';
import { TextInput } from '../../../ui/InputFields';
import Selectbox from '../../../components/Utils/Selectbox/Selectbox';
import { Campaigns, Questions, Comment } from '../../../modals/modals';
import { countriesList, subLocations } from '../../../utils/idibuJSON';
import TeamComp from '../../../components/Users/UsersComponent';
import {
  getUserByIDSlice,
  getUsersByEnterpriseIDSlice,
} from '../../../redux/user/user-slice';
import { fetchAllEmployerByEnterprise } from '../../../redux/clients/clients-slice';
import CandidateList from '../../campaign/Common/CampaignSection/CandidateList';
import { IconButton, Typography } from '@mui/material';
import AccordionExpandable from '../../../components/AccordionExpandable/AccordionExpandable';
import CardHeader from '../../campaign/CardHeader';
import { CircleLoader } from 'react-spinners';
import JobDescriptionForm from './JobDescriptionForm/JobDescriptionForm';
import axios from 'axios';
import {
  clearComment,
  createComment,
  createNotifications,
  deleteComment,
  deleteCommentReducer,
  getCampaignComment,
  updateComment,
  updateCommentsListReducer,
} from '../../../redux/comments/comments-slice';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as AIBlueIcon } from '../../../images/icons/blueAiIcon.svg';
import SliderInput from '../../../components/Utils/SliderInput/SliderInput';
import { DrawerRightNew } from '../../../components/dashboard/DrawerRightNew';
import { fetchTemplatesQuestionsById } from '../../../redux/templates/templates-slice';
import { useDrop } from 'react-dnd';
import { Theme } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import {
  generateAudioQuestion,
  generateInterviewEngagementVideos,
  generateSynthesia,
} from '../../../api/questions';
import { getPaymentDetailsByUser } from '../../../redux/payment/payments-slice';
import GooglePlacesInput from '../../../components/GooglePlacesInput/GooglePlacesInput';

interface LocationPropsState {
  item: Campaigns;
}

const useStyles: any = makeStyles((theme: Theme) => ({
  containerForm: {
    // overflow: 'auto',
    scrollbarWidth: 'none', // Hide the scrollbar for firefox
    '&::-webkit-scrollbar': {
      display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
    },
    '&-ms-overflow-style:': {
      display: 'none', // Hide the scrollbar for IE
    },
  },
}));

const CampaignForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationPropsState;
  const [enterpriseData, setenterpriseData] = useState<any>(null);
  const [adminUser, setAdminUser] = useState<any>(null);
  const [isJobCreatedModal, setJobCreatedModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { enterprises } = useAppSelector((state) => state.enterprises);
  const { questions, jobDescriptionHtml } = useAppSelector(
    (state) => state.questions
  );

  const [jobTitle, setJobTitle] = useState<string>('');
  const [selectedCompany, setselectedCompany] = useState<any>({});
  const [allEmployers, setAllEmployerList] = useState<any>([]);
  // const [campaignTitle, setCampaignTitle] = useState<string>('');
  const [campaignReferenceCode, setCampaignReferenceCode] =
    useState<string>('');
  const [cityValue, setCityValue] = useState<any>({
    label: '',
    value: '',
  });
  const [selectedCityValue, setSelectedCityValue] = useState<any>('');
  const [regionValue, setRegionValue] = useState<any>('');
  const [startDate, setStartDate] = useState<string | any>(
    formatDateToString(new Date())
  );
  const [endDate, setEndDate] = useState<string | any>(
    formatDateToString(moment().add(1, 'month').calendar())
  );
  const [campaignCommencementDate, setCampaignCommencementDate] = useState<
    Date | string
  >(startDate);
  const [campaignCompletionDate, setCampaignCompletionDate] = useState<
    Date | string
  >(endDate);
  const [mainJobCategoryValue, setMainJobCategoryValue] = useState<
    string | null
  >(null);
  const [subJobCategoryValue, setSubJobCategoryValue] = useState<string | null>(
    null
  );
  const [mainJobCategoryId, setMainCategoryId] = useState<number | null>(null);
  const [subJobCategoryId, setSubCategoryId] = useState<number | null>(null);
  const [anchorElOpenMainCategory, setAnchorElOpenMainCategory] =
    useState<null | HTMLElement>(null);
  const [anchorElOpenSubCategory, setAnchorElOpenSubCategory] =
    useState<null | HTMLElement>(null);
  const [anchorElOpenCity, setAnchorElOpenCity] = useState<null | HTMLElement>(
    null
  );
  const [anchorElOpenRegion, setAnchorElOpenRegion] =
    useState<null | HTMLElement>(null);
  const [anchorElOpenWorkType, setAnchorElOpenWorkType] =
    useState<null | HTMLElement>(null);
  const [anchorElOpenPayDetails, setAnchorElOpenPayDetails] =
    useState<null | HTMLElement>(null);
  const [workType, setWorkType] = useState<string | null>(null);
  const [payType, setPayType] = useState<string | null>(null);
  const [fromValue, setFromValue] = useState<number | null>(null);
  const [toValue, setTovalue] = useState<number | null>(null);
  const [mainCategoryArr, setMainCategoryArr] = useState<any>([]);
  const [subCategoryArr, setSubCategoryArr] = useState<any>([]);
  const [jobLocationRegionArr, setJobLocationRegionArr] = useState<any>([]);
  const { mainJobCategory, jobLocationRegion, subJobCategory } = useAppSelector(
    (state) => state.campaigns
  );
  const [isEnterpriseSelected, setIsEnterpriseSelected] = useState(true);
  const [isUpdatedJobCategory, setJobCategoryUpdated] = useState(false);
  const [isHiringManagerChangeModal, setHiringManagerChangeModal] =
    useState<boolean>(false);
  const [expandState, setExpandState] = useState([
    { element: 1, state: true },
    { element: 2, state: true },
    { element: 3, state: true },
  ]);

  const [isEmployerChangeModal, setEmployerChangeModal] =
    useState<boolean>(false);
  const [selectedEmployer, setSelectedEmployer] = useState({
    employerName: '',
    employerId: 0,
    employerPic: '',
    employerInitials: '',
    employerBgColor: '',
    employerColor: '',
  });
  const [selected, setSelected] = useState<any>('AU');
  const { enterpris } = useAppSelector((state) => state.enterprises);

  const { employersList } = useAppSelector((state) => state.clients);

  const { paymentInfo } = useAppSelector((state: any) => state.payment);

  const workTypeRef = useRef(null);

  // let isAIApiCallQuestion = true;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openAnchor = Boolean(anchorEl);

  const CommentsRef = useRef(null);

  const [campaignComments, setCampaignComments] = useState<Comment[]>([]);
  const [addNewComment, setAddNewComment] = useState<boolean>(false);
  const [iscallApiByEnter, setIscallApiByEnter] = useState<boolean>(false);

  const [newComment, setNewComment] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const { campaigncomments } = useAppSelector((state) => state.comments);

  const [userListData, setUserListData] = useState<[]>();
  const [allCommentUserList, setAllCommentUserList] = useState<[]>();
  const [mentionsData, setMentionsData] = useState<any>([]);

  const [isQuestionDragged, setIsQuestionDragged] = useState(false);
  const [playAudioIndex, setPlayAudioIndex] = useState<undefined | number>(
    undefined
  );

  const [addNewQuestion, setAddNewQuestion] = useState<boolean>(false);
  const QuestionRef = useRef(null);
  const forceUpdate = useForceUpdate();
  const [newQuestion, setNewQuestion] = useState<string>('');
  const [campaignDesc, setCampaignDesc] = useState<string>('');
  const [campaignDescHtml, setcampaignDescHtml] = useState<string>('');

  const [interviewDetails, setInterviewDetails] = useState({
    engagementText: '',
    introductionText:
      'Hello and welcome to the interview. Relax and enjoy yourself as I ask you a few questions',
    closingText:
      'That was great, Congratulations on completing the interview. We will assess your interview and get back to you shortly.',
  });

  const { userData, userById, usersByEnterpriseId } = useAppSelector(
    (state: any) => state.user
  );
  useEffect(() => {
    dispatch(getJobMainCategoriesCampaign());

    dispatch(getJobLocationRegionCampaign());
  }, [anchorElOpenMainCategory]);

  useEffect(() => {
    if (!enterpris && userData) {
      dispatch(fetchEnterprisesById(userData?.fk_Enterprise_ID));
    }
    if (!enterprises) {
      dispatch(fetchAllEnterprises());
    }
  }, []);

  useEffect(() => {
    var array: any = [];
    const abc = mainJobCategory?.map((ab: any, index: number) => {
      return array.push(ab);
    });
    setMainCategoryArr(array);
  }, [mainJobCategory, state]);

  useEffect(() => {
    var array: any = [];
    const abc = subJobCategory?.map((ab: any, index: number) => {
      return array.push(ab);
    });
    setSubCategoryArr(array);
  }, [subJobCategory]);

  useEffect(() => {
    setAdminUser(userById ?? userData);
  }, [userById]);

  useEffect(() => {
    if (state) {
      const { item } = state;

      setJobTitle(item.Campaign_Name);
      setCampaignCompletionDate(
        formatDateToString(state?.item?.Campaign_EndDate)
      );
      setCampaignCommencementDate(
        formatDateToString(state?.item?.Campaign_StartDate)
      );
      setEndDate(endDate);
      setStartDate(startDate);
      setCampaignReferenceCode(state?.item?.Campaign_RefernceCode);
      // setCityValue(
      //   state?.item?.Campaign_Job_Location_Text
      //     ? {
      //         label: state?.item?.Campaign_Job_Location_Text,
      //         value: state?.item?.Campaign_Job_Location_Text,
      //       }
      //     : ''
      // );
      setSelectedCityValue(state?.item?.Campaign_Job_Location_City_ID ?? '');
      setRegionValue(state?.item?.Campaign_Job_Location_Region_ID);
      setTovalue(state?.item?.Campaign_Job_Pay_Range_To);
      setFromValue(state.item.Campaign_Job_Pay_Range_From);
      setPayType(
        state.item.Campaign_Job_Pay_Type_ID == 1
          ? 'Hourly rate'
          : state.item.Campaign_Job_Pay_Type_ID == 2
          ? 'Annual salary'
          : state.item.Campaign_Job_Pay_Type_ID == 2
          ? 'Annual pluscommission'
          : ''
      );
      setWorkType(
        state.item.Campaign_Job_Work_Type_ID == 1
          ? 'Full-time'
          : state.item.Campaign_Job_Work_Type_ID == 2
          ? 'Part-time'
          : state.item.Campaign_Job_Work_Type_ID == 3
          ? 'Contract'
          : state.item.Campaign_Job_Work_Type_ID == 4
          ? 'Casual'
          : ''
      );
      setSelected(state.item?.Campaign_Job_Location_Country_ID);
    } else {
      dispatch(clearQuestion());
      dispatch(clearComment());
    }
  }, [state]);

  useEffect(() => {
    if (jobDescriptionHtml) {
      dispatch(updateHtmlDes(null));
    }
  }, []);
  useEffect(() => {
    if (enterpris) {
      setenterpriseData(enterpris);
      dispatch(getUserByIDSlice(enterpris.fk_UserID_MainContact));
      const object = {
        enterpriseId: enterpris.pk_Enterprise_ID,
        searchText: '',
      };
      dispatch(getUsersByEnterpriseIDSlice(object));
    }
  }, [enterpris, userData]);

  useEffect(() => {
    if (usersByEnterpriseId?.length > 0) {
      const filteredEmployer = usersByEnterpriseId.find(
        (employer) =>
          employer.pk_User_ID === state?.item?.Campaign_CandidateNumber
      );

      setSelectedEmployer({
        employerName:
          filteredEmployer?.User_FirstName?.substring(0, 6) ??
          userData?.User_FirstName?.substring(0, 6),
        employerId: filteredEmployer?.pk_User_ID ?? userData?.pk_User_ID,
        employerPic:
          filteredEmployer?.User_ImageLink ?? userData?.User_ImageLink ?? '',
        employerInitials:
          filteredEmployer?.User_Initials ?? userData?.User_Initials,
        employerBgColor:
          filteredEmployer?.User_BackgroundColour ??
          userData?.User_BackgroundColour,
        employerColor: filteredEmployer?.User_Colour ?? userData?.User_Colour,
      });
    }
  }, [userData, usersByEnterpriseId, enterpris]);

  useEffect(() => {
    if (state && mainCategoryArr.length > 0) {
      if (!isUpdatedJobCategory) {
        const { item } = state;
        const filter = mainCategoryArr.filter(
          (mj) => mj.JobId === item.Campaign_Job_Category_ID
        );
        if (filter) {
          setMainJobCategoryValue(filter[0]?.JobName);
          setMainCategoryId(item?.Campaign_Job_Category_ID);
        }
        setJobCategoryUpdated(true);
      }
    }
  }, [mainCategoryArr]);

  useEffect(() => {
    if (state) {
      const { item } = state;
      const filter = subCategoryArr.filter(
        (jsc) => jsc.JobSubCategoryId === item?.Campaign_Job_Category_Sub_ID
      );
      if (filter) {
        setSubJobCategoryValue(filter[0]?.JobSubCategoryName);
        setSubCategoryId(item?.Campaign_Job_Category_Sub_ID);
      }
    }
  }, [subCategoryArr]);

  useEffect(() => {
    if (mainJobCategoryId) {
      dispatch(getJobSubCategoriesCampaign(mainJobCategoryId));
    }
  }, [mainJobCategoryId]);

  // useEffect(() => {
  //   if (enterprises && enterprises.length > 0 && userData) {
  //     const getSpecificCompany = enterprises.filter(
  //       (itm) => itm.pk_Enterprise_ID === userData?.fk_Enterprise_ID
  //     );
  //     console.log('getSpecificCompany', getSpecificCompany);

  //     if (getSpecificCompany.length > 0) {
  //       setselectedCompany(body);
  //     }
  //   }
  // }, [enterprises, userData]);

  // console.log('CORRECT', state);

  useEffect(() => {
    if (state && enterpris) {
      const data = {
        userId: enterpris?.fk_User_ID,
        enterpriseId: state?.item?.fk_Enterprise_ID,
      };
      dispatch(getPaymentDetailsByUser(data));
    }
  }, [state, enterpris]);

  useEffect(() => {
    if (enterprises && enterprises.length > 0 && userData) {
      const getSpecificCompany = enterprises.filter(
        (itm) => itm.pk_Enterprise_ID === userData.fk_Enterprise_ID
      );
      if (getSpecificCompany.length > 0) {
        setIsEnterpriseSelected(true);
        setselectedCompany(getSpecificCompany[0]);
      }
    }
  }, [enterprises, userData]);

  useEffect(() => {
    if (
      userData &&
      employersList !== null &&
      employersList?.length > 0 &&
      state
    ) {
      const getSpecificCompany = employersList?.filter(
        (itm) => itm?.pk_Employer_ID === state?.item?.Campaign_Tolerance
      );

      const body = {
        Enterprise_AccessLevel: getSpecificCompany[0]?.Employer_AccessLevel,
        Enterprise_AlternateNumber:
          getSpecificCompany[0]?.Employer_AlternateNumber,
        Enterprise_AlternateNumber_AreaCode:
          getSpecificCompany[0]?.Employer_AlternateNumber_AreaCode,
        Enterprise_AlternateNumber_CountryCode:
          getSpecificCompany[0]?.Employer_AlternateNumber_CountryCode,
        Enterprise_AlternateNumber_Extention:
          getSpecificCompany[0]?.Employer_AlternateNumber_Extention,
        Enterprise_AvatarEnable: getSpecificCompany[0]?.Employer_AvatarEnable,
        Enterprise_BackgroundColour:
          getSpecificCompany[0]?.Employer_BackgroundColour ??
          Colors.Maximum_blue_purple,
        Enterprise_BussinessNumber: getSpecificCompany[0]?.Employer_AccessLevel,
        Enterprise_City: getSpecificCompany[0]?.Employer_City,
        Enterprise_Colour: getSpecificCompany[0]?.Employer_Colour ?? '#000',
        Enterprise_CompanyNumber: getSpecificCompany[0]?.Employer_CompanyNumber,
        Enterprise_Country: getSpecificCompany[0]?.Employer_Country,
        Enterprise_CountryCoded: getSpecificCompany[0]?.Employer_CountryCoded,
        Enterprise_DUNs: getSpecificCompany[0]?.Employer_DUNs,
        Enterprise_DateOfCreation:
          getSpecificCompany[0]?.Employer_DateOfCreation,
        Enterprise_DateOfLastUpdate:
          getSpecificCompany[0]?.Employer_DateOfLastUpdate,
        Enterprise_Deleted: getSpecificCompany[0]?.Employer_Deleted,
        Enterprise_Email: getSpecificCompany[0]?.Employer_Email,
        Enterprise_ImageLink: getSpecificCompany[0]?.Employer_ImageLink,
        Enterprise_Initials: getSpecificCompany[0]?.Employer_Initials,
        Enterprise_Name: getSpecificCompany[0]?.Employer_Name,
        Enterprise_Name2: getSpecificCompany[0]?.Employer_Name2,
        Enterprise_Notes: getSpecificCompany[0]?.Employer_Notes,
        Enterprise_PhoneNumber: getSpecificCompany[0]?.Employer_PhoneNumber,
        Enterprise_PhoneNumber_AreaCode:
          getSpecificCompany[0]?.Employer_PhoneNumber_AreaCode,
        Enterprise_PhoneNumber_CountryCode:
          getSpecificCompany[0]?.Employer_PhoneNumber_CountryCode,
        Enterprise_PhoneNumber_Extention:
          getSpecificCompany[0]?.Employer_PhoneNumber_Extention,
        Enterprise_Postcode: getSpecificCompany[0]?.Employer_Postcode,
        Enterprise_Record_GUID: getSpecificCompany[0]?.Employer_Record_GUID,
        Enterprise_RefernceCode: getSpecificCompany[0]?.Employer_RefernceCode,
        Enterprise_Region: getSpecificCompany[0]?.Employer_Region,
        Enterprise_Status: getSpecificCompany[0]?.Employer_Status,
        Enterprise_StreetAddress: getSpecificCompany[0]?.Employer_StreetAddress,
        Enterprise_StreetAddress2:
          getSpecificCompany[0]?.Employer_StreetAddress2,
        Enterprise_StreetAddress3:
          getSpecificCompany[0]?.Employer_StreetAddress3,
        Enterprise_TaxRegistrationNumber:
          getSpecificCompany[0]?.Employer_TaxRegistrationNumber,
        fk_Enterprise_ID: getSpecificCompany[0]?.fk_Enterprise_ID,
        fk_UserID_MainContact: getSpecificCompany[0]?.fk_UserID_MainContact,
        fk_User_ID: getSpecificCompany[0]?.fk_User_ID,
        isAgencies: false,
        pk_Enterprise_ID: getSpecificCompany[0]?.pk_Employer_ID,
        sSession: getSpecificCompany[0]?.sSession,
      };

      if (getSpecificCompany.length > 0) {
        setIsEnterpriseSelected(false);
        setselectedCompany(body);
      }
    }
  }, [enterprises, userData, employersList]);

  const handleUpdateEmployerSelection = (i) => {
    let body = i;

    if (i?.pk_Employer_ID) {
      body = {
        Enterprise_AccessLevel: i?.Employer_AccessLevel,
        Enterprise_AlternateNumber: i?.Employer_AlternateNumber,
        Enterprise_AlternateNumber_AreaCode:
          i?.Employer_AlternateNumber_AreaCode,
        Enterprise_AlternateNumber_CountryCode:
          i?.Employer_AlternateNumber_CountryCode,
        Enterprise_AlternateNumber_Extention:
          i?.Employer_AlternateNumber_Extention,
        Enterprise_AvatarEnable: i?.Employer_AvatarEnable,
        Enterprise_BackgroundColour:
          i?.Employer_BackgroundColour ?? Colors.Maximum_blue_purple,
        Enterprise_BussinessNumber: i?.Employer_AccessLevel,
        Enterprise_City: i?.Employer_City,
        Enterprise_Colour: i?.Employer_Colour ?? '#000',
        Enterprise_CompanyNumber: i?.Employer_CompanyNumber,
        Enterprise_Country: i?.Employer_Country,
        Enterprise_iCountryCoded: i?.Employer_CountryCoded,
        Enterprise_DUNs: i?.Employer_DUNs,
        Enterprise_DateOfCreation: i?.Employer_DateOfCreation,
        Enterprise_DateOfLastUpdate: i?.Employer_DateOfLastUpdate,
        Enterprise_Deleted: i?.Employer_Deleted,
        Enterprise_Email: i?.Employer_Email,
        Enterprise_ImageLink: i?.Employer_ImageLink,
        Enterprise_Initials: i?.Employer_Initials,
        Enterprise_Name: i?.Employer_Name,
        Enterprise_Name2: i?.Employer_Name2,
        Enterprise_Notes: i?.Employer_Notes,
        Enterprise_PhoneNumber: i?.Employer_PhoneNumber,
        Enterprise_PhoneNumber_AreaCode: i?.Employer_PhoneNumber_AreaCode,
        Enterprise_PhoneNumber_CountryCode: i?.Employer_PhoneNumber_CountryCode,
        Enterprise_PhoneNumber_Extention: i?.Employer_PhoneNumber_Extention,
        Enterprise_Postcode: i?.Employer_Postcode,
        Enterprise_Record_GUID: i?.Employer_Record_GUID,
        Enterprise_RefernceCode: i?.Employer_RefernceCode,
        Enterprise_Region: i?.Employer_Region,
        Enterprise_Status: i?.Employer_Status,
        Enterprise_StreetAddress: i?.Employer_StreetAddress,
        Enterprise_StreetAddress2: i?.Employer_StreetAddress2,
        Enterprise_StreetAddress3: i?.Employer_StreetAddress3,
        Enterprise_TaxRegistrationNumber: i?.Employer_TaxRegistrationNumber,
        fk_Enterprise_ID: i?.fk_Enterprise_ID,
        fk_UserID_MainContact: i?.fk_UserID_MainContact,
        fk_User_ID: i?.fk_User_ID,
        isAgencies: false,
        pk_Enterprise_ID: i?.pk_Employer_ID,
        sSession: i?.sSession,
      };
      setIsEnterpriseSelected(false);
    } else {
      setIsEnterpriseSelected(true);
    }

    setselectedCompany(body);
    setEmployerChangeModal(false);
  };

  useEffect(() => {
    var array = [];
    for (var prop in jobLocationRegion) {
      if (jobLocationRegion.hasOwnProperty(prop)) {
        array.push(prop);
      }
    }
    setJobLocationRegionArr(array);
  }, [jobLocationRegion]);

  const convertToFormdata = (data: any) => {
    const formData = new URLSearchParams();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    const urlEncodedString = formData.toString();
    return urlEncodedString;
  };

  const handleGenerateAudio = () => {
    if (questions.length === 0) {
      toastFailure('Question is beign generated. Please wait for a moment');
      return;
    }
    if (!selectedCompany.pk_Enterprise_ID && !selectedCompany.pk_Employer_ID) {
      toastFailure('Please select a Company');
      return;
    }
    if (jobTitle.trim().length == 0) {
      toastFailure('Campaign Title is required.');
      return;
    }
    setLoading(true);

    // const tempArray = [];
    // const questionsWithoutAudio = questions?.filter(
    //   (item) => item.Question_AudioFileUrl === ''
    // );
    const settingDataObj = [
      {
        Setting_Name: 'Job_Voice',
        Setting_Description: '',
        fk_Setting_Category_ID: 4,
        Setting_Status: 1,
        Setting_DateOfCreation: '2023-08-18T00:00:00',
        Setting_Deleted: 0,
        Setting_Value: 'en-AU-AnnetteNeural',
        fk_Enterprise_ID: userData?.fk_Enterprise_ID,
        fk_Campaign_ID: state.item?.pk_Campaign_ID,
        fk_User_ID: userData?.pk_User_ID,
      },
    ];
    dispatch(createJobSettings(settingDataObj))
      .unwrap()
      .then((responseSetting) => {
        if (
          responseSetting.response.status === 200 ||
          responseSetting.response.successful === true
        ) {
          generateAudioQuestion(questions)
            .then((res: any) => {
              if (res.status === 200 || res.status === 201) {
                // isAudioGeneratedNew = false;
                // setAudioGenerationIsStarted(false);
                // setIsAudioApiCall(false);
                dispatch(fetchQuestions(state.item.pk_Campaign_ID));
                const transformedData = questions.map((item) => {
                  const {
                    pk_Question_ID,
                    fk_Enterprise_ID,
                    sSession,
                    fk_User_ID,
                    fk_Job_ID,
                    fk_Campaign_ID,
                    Tironem_Question_Text: Question_Text,
                    Question_RecruiterRating,
                    Question_Order,
                    Question_Deleted,
                    Question_Status,
                    Question_AccessLevel,
                  } = item;

                  return {
                    pk_Question_ID,
                    fk_Enterprise_ID,
                    sSession,
                    fk_User_ID,
                    fk_Job_ID,
                    fk_Campaign_ID,
                    Question_Text,
                    Question_RecruiterRating,
                    Question_Order,
                    Question_Deleted,
                    Question_Status,
                    Question_AccessLevel,
                  };
                });

                generateSynthesia(transformedData).then((response) => {});
                toastSuccess(res?.data || '');
                handleSubmit();
              } else {
                setLoading(false);
                // setAudioGenerationIsStarted(false);
                toastFailure('Something went wrong, please try again.');
              }
            })
            .catch((err) => {
              setLoading(false);
              // setIsAudioApiCall(false);
              // setAudioGenerationIsStarted(false);
              toastFailure('Something went wrong, please try again.');
            });
        } else {
          setLoading(false);
          toastFailure('Something went wrong, please try again.');
        }
      });
  };

  const handleSubmit = () => {
    // if (questions.length <= 0 && path !== 'back') {
    //   // scrollDownFun(QuestionRef);
    //   toastFailure('Please add Questions.');
    //   return;
    // }
    // if (isAudioGeneratedNew && path !== 'back') {
    // setAudioGenerationIsStarted(true);
    // handleGenerateAudio();
    // return;
    // }

    if (
      interviewDetails.closingText !== '' ||
      interviewDetails.engagementText !== '' ||
      interviewDetails.introductionText !== ''
    ) {
      const synthesiaData = [
        {
          fk_Enterprise_ID: state.item.fk_Enterprise_ID,
          fk_User_ID: state.item.fk_User_ID,
          sSession: 'kjgjgkjg746657',
          SynthesiaVideoIntegration_FkId: state.item.pk_Campaign_ID,
          SynthesiaVideoIntegration_FkTable: 'Campaign',
          SynthesiaVideoIntegration_FieldName: 'Engagement Dialog',
          SynthesiaVideoIntegration_Text: interviewDetails.engagementText,
        },
        {
          fk_Enterprise_ID: state.item.fk_Enterprise_ID,
          fk_User_ID: state.item.fk_User_ID,
          sSession: 'kjgjgkjg746657',
          SynthesiaVideoIntegration_FkId: state.item.pk_Campaign_ID,
          SynthesiaVideoIntegration_FkTable: 'Campaign',
          SynthesiaVideoIntegration_FieldName: 'Interview Introduction Dialog',
          SynthesiaVideoIntegration_Text: interviewDetails.introductionText,
        },
        {
          fk_Enterprise_ID: state.item.fk_Enterprise_ID,
          fk_User_ID: state.item.fk_User_ID,
          sSession: 'kjgjgkjg746657',
          SynthesiaVideoIntegration_FkId: state.item.pk_Campaign_ID,
          SynthesiaVideoIntegration_FkTable: 'Campaign',
          SynthesiaVideoIntegration_FieldName: 'Interview Closing Dialog',
          SynthesiaVideoIntegration_Text: interviewDetails.closingText,
        },
      ];
      generateInterviewEngagementVideos(synthesiaData)
        .then((response: any) => {})
        .catch((error) => {
          console.log('EROROR', error);
        });
    }

    handleJobSubmit();

    // if (path === 'back') {
    //   navigate('/campaign');
    // } else {
    //   navigate(Urls.CreateCampaign, {
    //     state: {
    //       item: state.item,
    //     },
    //   });
    // }
  };

  const handleJobSubmit = () => {
    const body = {
      Campaign_Record_GUID: '80190c95-fe7e-46a1-9027-f0f9bd70330c',
      fk_Enterprise_ID: userData?.fk_Enterprise_ID,
      sSession: 'Admin',
      fk_User_ID: userData?.pk_User_ID,
      // fk_Job_ID: 6,
      Campaign_RefernceCode: campaignReferenceCode,
      Campaign_Name: jobTitle,
      Campaign_StartDate: formatDateStringForPayoad(
        formatStringToDate(campaignCommencementDate)
      ),
      Campaign_EndDate: formatDateStringForPayoad(
        formatStringToDate(campaignCompletionDate)
      ),
      Campaign_Job_Title: jobTitle,
      Campaign_Job_Location_Country_ID: countriesList?.find(
        (cl) => cl.countryCode === selected
      )?.countryId,
      Campaign_Job_Location_Region_ID: regionValue,
      Campaign_Job_Location_City_ID: cityValue.value ?? selectedCityValue,
      Campaign_Job_Location_Text:
        cityValue.label ??
        subLocations.filter((sb) => sb.value === selectedCityValue)[0]?.label,
      Campaign_Job_Category_ID: mainJobCategoryValue ? mainJobCategoryId : null,
      Campaign_Job_Category_Sub_ID: subJobCategoryValue
        ? subJobCategoryId
        : null,
      Campaign_Job_Category_Text: 'sample string 28',
      Campaign_Job_Refernce_Code: 'sample string 29',
      Campaign_Job_Work_Type_ID:
        workType == 'Full-time'
          ? 1
          : workType == 'Part-time'
          ? 2
          : workType == 'Contract'
          ? 3
          : workType == 'Casual'
          ? 4
          : 0,
      Campaign_Job_Pay_Type_ID:
        payType == 'Hourly rate'
          ? 1
          : payType == 'Annual salary'
          ? 2
          : payType == 'Annual pluscommission'
          ? 3
          : 0,
      Campaign_Job_Pay_Range_From: fromValue,
      Campaign_Job_Pay_Range_To: toValue,
      Campaign_Job_Video_Link: 'sample string 34',
      Campaign_Job_Summary: 'sample string 35',
      Campaign_DateOfCreation: '2023-03-24T16:32:18.3371695+11:00',
      Campaign_DateOfLastUpdate: '2023-03-24T16:32:18.3381719+11:00',
      Campaign_Deleted: 40,
      fk_Alogorithm_ID: 2,
      fk_Campaign_CountryLocal: 2,
      fk_Campaign_LanguageName: 2,
      fk_Campaign_VoiceSelection: 2,
      Campaign_CandidateNumber: selectedEmployer.employerId,
      Campaign_Tolerance: isEnterpriseSelected
        ? 0
        : selectedCompany.pk_Enterprise_ID ?? selectedCompany?.pk_Employer_ID,
      Campaign_VideoQuality: 4,
      Campaign_FaceDataVariance: 6,
      Campaign_VoiceDataVariance: 9,
      Campaign_VideoDataVariance: 12,
      Campaign_Status: 90,
      Campaign_AccessLevel: 1,
      Campaign_Job_Description: campaignDesc ?? '',
      Campaign_Job_DescriptionHtml: campaignDescHtml ?? '',
    };

    const data = {
      ...body,
      pk_Campaign_ID: state.item.pk_Campaign_ID,
    };
    dispatch(updateCampaigns(data))
      .unwrap()
      .then((responseUpdateCampaign: any) => {
        const combineCompanignData: any = {
          ...body,
          pk_Campaign_ID: state.item.pk_Campaign_ID,
          Campaign_StartDate: formatDateStringForPayoad(
            formatStringToDate(campaignCommencementDate)
          ),
          Campaign_EndDate: formatDateStringForPayoad(
            formatStringToDate(campaignCompletionDate)
          ),
        };

        const stripeSecretKey =
          'sk_test_51NfcZyGGLSSFOLKvlulSwGjZtmvGYEh2UhpAjKmQJ0KxGUpMsJfaZm2sGezkT4aWYVuW9zCh2EYOMEUe3G7osYYa00sASTAa5q';

        if (paymentInfo.StripePayment_ClientID !== null) {
          const headers = {
            Authorization: `Bearer ${stripeSecretKey}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          };
          const quantityData = {
            quantity: 1,
          };
          const convertQuantityData = convertToFormdata(quantityData);
          axios
            .post(
              `https://api.stripe.com/v1/subscription_items/${paymentInfo?.StripePayment_OptionalField4}/usage_records`,
              convertQuantityData,
              {
                headers: headers,
              }
            )
            .then((quantityResponse) => {})
            .catch((errorQuantity: any) => {
              console.log('errorQuantity', errorQuantity);
            });
        }
        setTimeout(() => {
          setLoading(false);
          // navigate(Urls.Campaign);
          setJobCreatedModal(true);
          // navigate(Urls.InstantInviteCamaign, {
          //   state: {
          //     item: combineCompanignData,
          //     Enterperise_Name:
          //       selectedCompany.Enterprise_Name ??
          //       selectedCompany?.Employer_Name,
          //   },
          // });
          // toastSuccess('Job is succesfully created');
        }, 500);
      })
      .catch((errorcmapaign) => {});
  };

  const handleClickMainCategory = (
    event: React.MouseEvent<HTMLDivElement>,
    type: string
  ) => {
    if (type == 'MainCategory') {
      setAnchorElOpenMainCategory(event.currentTarget);
    } else if (type == 'SubCategory') {
      if (mainJobCategoryId) {
        setAnchorElOpenSubCategory(event.currentTarget);
      } else {
        toastFailure('Please select a Job Category');
      }
    } else if (type == 'City') {
      setAnchorElOpenCity(event.currentTarget);
    } else if (type == 'Region') {
      setAnchorElOpenRegion(event.currentTarget);
    } else if (type == 'WorkType') {
      setAnchorElOpenWorkType(event.currentTarget);
    } else if (type == 'PayId') {
      setAnchorElOpenPayDetails(event.currentTarget);
    }
  };
  const handleCloseMainCategory = (type: string) => {
    if (type == 'MainCategory') {
      setAnchorElOpenMainCategory(null);
    } else if (type == 'SubCategory') {
      setAnchorElOpenSubCategory(null);
    } else if (type == 'City') {
      setAnchorElOpenCity(null);
    } else if (type == 'Region') {
      setAnchorElOpenRegion(null);
    } else if (type == 'WorkType') {
      setAnchorElOpenWorkType(null);
    } else if (type == 'PayId') {
      setAnchorElOpenPayDetails(null);
    }
  };

  const setSelectionBoxValue = (val: string, type: string, id?: number) => {
    if (type) {
      if (type == 'MainCategory') {
        setMainJobCategoryValue(val);
        setMainCategoryId(id ? +id : null);
        setAnchorElOpenMainCategory(null);
      } else if (type == 'SubCategory') {
        setAnchorElOpenSubCategory(null);
        setSubJobCategoryValue(val);
        setSubCategoryId(id ? +id : null);
      } else if (type == 'City') {
        setAnchorElOpenCity(null);
      } else if (type == 'Region') {
        setAnchorElOpenRegion(null);
      } else if (type == 'WorkType') {
        setWorkType(val);
        setAnchorElOpenWorkType(null);
      } else if (type == 'PayId') {
        setPayType(val);
        setAnchorElOpenPayDetails(null);
      }
    }
  };

  const filterOptions = (options, { inputValue }) => {
    return options.filter((option: any) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleAccordionState = (current) => {
    const temp = [...expandState];
    const index = expandState.findIndex((e) => e.element === current);
    temp[index] = { ...expandState[index] };
    temp[index].state = !temp[index].state;
    // console.log('current', index);
    setExpandState(temp);
  };

  useEffect(() => {
    if (selectedCompany && enterprises && enterprises.length > 0) {
      const obj = {
        enterpriseId: userData?.fk_Enterprise_ID,
        ps: 50,
        pn: 1,
      };
      const getSpecificCompany = enterprises.filter(
        (itm) => itm.pk_Enterprise_ID === userData?.fk_Enterprise_ID
      );

      if (allEmployers.length === 0) {
        dispatch(fetchAllEmployerByEnterprise(obj))
          .unwrap()
          .then((res: any) => {
            setAllEmployerList([
              { ...getSpecificCompany[0] },
              ...res?.employerResponse.data,
            ]);
          })
          .catch((campaignError: any) => {
            // console.log('campaignError', campaignError);
          });
      }
    }
  }, [selectedCompany, userData]);

  ///////////

  function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update state to force render
    // A function that increment 👆🏻 the previous state like here
    // is better than directly setting `setValue(value + 1)`
  }
  const [isAIApiCallQuestion, setIsAIApiCallQuestion] =
    React.useState<null | Boolean>(false);

  const [itemsQuestion, setItemsQuestion] = useState<any>([]);

  useEffect(() => {
    setItemsQuestion(questions);
  }, [questions]);

  useEffect(() => {
    setIsAIApiCallQuestion(true);
    setTimeout(() => {
      handleGPTGenerateQuestions(false);
    }, 5000);
  }, [userData]);

  const handleDropQuestion = (item: any, questionsListArg?: any) => {
    if (!state) {
      toastFailure('Please select a company !');
    } else {
      const temp = questions && questions.length ? [...questions] : [];

      item.data.forEach((element: any) => {
        const questionsIndex = temp.length
          ? temp.findIndex((questionItem) => {
              return (
                questionItem.Tironem_Question_Text ==
                element.Tironem_Question_Text
              );
            })
          : -1;

        if (questionsIndex < 0) {
          dispatch(createQuestions(element))
            .unwrap()
            .then((originalPromiseResult: any) => {
              dispatch(fetchQuestions(state.item.pk_Campaign_ID));
            })
            .catch((rejectedValueOrSerializedError: any) => {
              console.log('getting error', rejectedValueOrSerializedError);
            });

          // temp.push(element)
        } else {
          toastFailure(
            `This Question is already exist`
            // `This Question( ${element.Tironem_Question_Text}) is already exist`
          );
        }
      });
    }
  };

  const hanldeAddNewQuestion = () => {
    const data = {
      pk_Question_ID: Math.ceil(Math.random() * 500),
      fk_Enterprise_ID: state.item.fk_Enterprise_ID,
      fk_User_ID: userData?.pk_User_ID,
      sSession: state.item.sSession,
      fk_Job_ID: 1,
      fk_Campaign_ID: state.item.pk_Campaign_ID,
      Tironem_Question_Text: newQuestion,
      Question_RecruiterRating: 2,
      Question_Order: itemsQuestion.length || 0,
      Question_Deleted: 1,
      Question_Status: 2,
      Question_AccessLevel: 2,
      Question_Record_GUID: '',
      Question_DateOfCreation: new Date().toISOString(),
      Question_DateOfLastUpdate: new Date().toISOString(),
    };

    handleDropQuestion({ data: [data] });
    setAddNewQuestion(false);
    setNewQuestion('');
  };

  const handleQuestionRemove = (question: Questions) => {
    dispatch(deleteQuestions(question))
      .unwrap()
      .then((originalPromiseResult: any) => {
        dispatch(deleteQuestionReducer({ item: question }));
      })
      .catch((rejectedValueOrSerializedError: any) => {
        console.log('getting error', rejectedValueOrSerializedError);
      });
  };

  const handleQuestionUpdate = (questionId: number, value: string) => {
    const temp = [...questions];
    const index = temp.findIndex((item) => item.pk_Question_ID === questionId);
    temp[index] = { ...temp[index] };
    temp[index].Tironem_Question_Text = value;

    dispatch(updateQuestions(temp[index]))
      .unwrap()
      .then((originalPromiseResult: any) => {
        dispatch(updateQuestionsListReducer({ item: temp }));
      })
      .catch((rejectedValueOrSerializedError: any) => {
        console.log('getting error', rejectedValueOrSerializedError);
      });
    // setQuestionsList(temp);
  };

  useEffect(() => {
    if (iscallApiByEnter) {
      handleAddNewComment();
      setIscallApiByEnter(false);
    }
  }, [iscallApiByEnter]);

  useEffect(() => {
    if (campaigncomments.length > 0) {
      setCampaignComments(
        campaigncomments.filter(
          (data: Comment) =>
            data?.fk_Campaign_ID == state?.item?.pk_Campaign_ID &&
            data?.Type == 0
        )
      );
    }
  }, [campaigncomments]);

  useEffect(() => {
    if (state) {
      dispatch(getCampaignComment()).catch(
        (rejectedValueOrSerializedError: any) => {
          console.log('getting error', rejectedValueOrSerializedError);
        }
      );
    } else {
      dispatch(clearComment());
    }
  }, [state]);

  useEffect(() => {
    if (userData) {
      const object = {
        enterpriseId: userData?.fk_Enterprise_ID,
        searchText: '',
      };
      dispatch(getUsersByEnterpriseIDSlice(object))
        .unwrap()
        .then((userResponse: any) => {
          const abc = userResponse.response.data;
          // setAllUserList(userResponse.response.data);
          const newArray: any = [];
          abc.map((item: any, index: number) =>
            newArray.push({
              display: item.User_FirstName + ' ' + item.User_SecondName,
              id: item.pk_User_ID,
            })
          );
          setUserListData(newArray);
        })
        .catch(() => {});
    }
  }, [userData]);

  // Cooment 777777
  const handleAddNewComment = () => {
    if (!state) {
      toastFailure('Please enter job title!');
    } else {
      function removeBracketsAndNumbers(inputText: any) {
        // Replace any occurrence of '[number]', '(', and ')' with an empty string
        const cleanedText = inputText.replace(/\[\d+\]|\(\d+\)/g, '');

        return cleanedText;
      }
      const cleanedText = removeBracketsAndNumbers(newComment);

      function removeSquareBrackets(inputText: any) {
        // Replace any occurrence of '[...]' with an empty string
        const cleanedTextSquare = inputText.replace(
          /\[\s*([^\]]+)\s*\]/g,
          '$1'
        );

        return cleanedTextSquare;
      }

      const cleanedTextSquare = removeSquareBrackets(cleanedText);
      const data = {
        fk_Campaign_ID: state?.item?.pk_Campaign_ID,
        fk_User_ID: userData?.pk_User_ID,
        Comment_Content: cleanedTextSquare,
        sSession: state?.item?.sSession,
        Type: 0,
        Reaction: 0,
        pk_Comment_ID: 1,
        Comment_Record_GUID: 'cde79f96-0fa4-4445-8781-710ff14aa872',
        fk_CampaignLine_ID: 0,
        fk_Question_ID: 0,
        Comment_Parent: 0,
        Comment_DateOfCreation: new Date().toISOString(),
        Comment_DateOfLastUpdate: new Date().toISOString(),
      };
      if (mentionsData.length > 0) {
        const array2IdsSet = new Set(mentionsData.map((item: any) => item.id));

        // Filter array1 to get objects with pk_User_ID matching array2's ids
        const filteredArray1 = allCommentUserList?.filter((item: any) =>
          array2IdsSet.has(item.pk_User_ID)
        );

        const object = {
          Notification_Title: 'Job Comments',
          Notification_Body: cleanedTextSquare,
          isEmail: 1,
          CreatedBy: 10,
          users: filteredArray1,
          fk_Campaign_ID: state?.item?.pk_Campaign_ID,
          fk_CampaignLine_ID: 0,
        };
        dispatch(createNotifications(object))
          .unwrap()
          .then((createNotification) => {
            setMentionsData([]);
            toastSuccess('Notification Sent');
          })
          .catch((error) => {
            console.log('error', error);
          });
      }

      dispatch(createComment(data))
        .unwrap()
        .then((originalPromiseResult: any) => {
          // dispatch(fetchComments(state.item.pk_Campaign_ID));
          dispatch(getCampaignComment());
          setAddNewComment(false);
          setNewComment('');
        })
        .catch((rejectedValueOrSerializedError: any) => {
          console.log('getting error', rejectedValueOrSerializedError);
        });
    }
  };

  const handleCommentUpdate = (commentId: number, value: string) => {
    function removeBracketsAndNumbers(inputText: any) {
      // Replace any occurrence of '[number]', '(', and ')' with an empty string
      const cleanedText = inputText.replace(/\[\d+\]|\(\d+\)/g, '');
      return cleanedText;
    }
    const cleanedText = removeBracketsAndNumbers(value.trim());
    function removeSquareBrackets(inputText: any) {
      // Replace any occurrence of '[...]' with an empty string
      const cleanedTextSquare = inputText.replace(/\[\s*([^\]]+)\s*\]/g, '$1');
      return cleanedTextSquare;
    }

    const cleanedTextSquare = removeSquareBrackets(cleanedText);

    const temp: Comment[] = [...campaigncomments];
    const index = temp.findIndex(
      (item: Comment) => item.pk_Comment_ID === commentId
    );
    temp[index] = { ...temp[index] };
    temp[index].Comment_Content = cleanedTextSquare;
    dispatch(updateComment(temp[index]))
      .unwrap()
      .then((originalPromiseResult: any) => {
        dispatch(updateCommentsListReducer({ item: temp }));
      })
      .catch((rejectedValueOrSerializedError: any) => {
        console.log('getting error', rejectedValueOrSerializedError);
      });
    if (mentionsData.length > 0) {
      const array2IdsSet = new Set(mentionsData.map((item: any) => item.id));

      // Filter array1 to get objects with pk_User_ID matching array2's ids
      const filteredArray1 = allCommentUserList?.filter((item: any) =>
        array2IdsSet.has(item.pk_User_ID)
      );

      const object = {
        Notification_Title: 'Job Comments',
        Notification_Body: cleanedTextSquare,
        isEmail: 1,
        CreatedBy: 10,
        users: filteredArray1,
        fk_Campaign_ID: state?.item?.pk_Campaign_ID,
        fk_CampaignLine_ID: 0,
      };
      dispatch(createNotifications(object))
        .unwrap()
        .then((createNotification) => {
          setMentionsData([]);
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  };

  const handleCommentRemove = (comment: Comment) => {
    dispatch(deleteComment(comment))
      .unwrap()
      .then((originalPromiseResult: any) => {
        dispatch(deleteCommentReducer({ item: comment }));
      })
      .catch((rejectedValueOrSerializedError: any) => {
        console.log('getting error', rejectedValueOrSerializedError);
      });
  };

  const onUpdateJDContent = (val: string, html: any) => {
    setCampaignDesc(val);
    setcampaignDescHtml(html);
  };

  useEffect(() => {
    if (isQuestionDragged) {
      itemsQuestion.forEach((questionUpdatedData: any) => {
        dispatch(updateQuestions(questionUpdatedData))
          .unwrap()
          .then((originalPromiseResult: any) => {
            setIsQuestionDragged(false);
          })
          .catch((rejectedValueOrSerializedError: any) => {
            console.log('getting error', rejectedValueOrSerializedError);
            setIsQuestionDragged(false);
          });
      });
    }
  }, [isQuestionDragged]);

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setItemsQuestion((prevItems: any) => {
      const newItems: any[] = Array.from(prevItems);

      const dragItem = {
        ...newItems[dragIndex],
        Question_Order: hoverIndex,
      };
      // const newItems = Array.from(prevItems);
      newItems.splice(dragIndex, 1);
      newItems.splice(hoverIndex, 0, dragItem);
      let finalItemsQuestions = newItems.map(
        (itemQuestion: any, itemQuestionIndex: number) => {
          let questionUpdatedData = {
            ...itemQuestion,
            Question_Order: itemQuestionIndex,
          };
          return questionUpdatedData;
        }
      );
      setIsQuestionDragged(true);
      return finalItemsQuestions;
    });
  }, []);

  const handleGPTGenerateQuestions = (isManualGenerate = false) => {
    setIsAIApiCallQuestion(true);
    if (!state) {
      toastFailure('Job Title is required.');
      setAnchorEl(null);
      setIsAIApiCallQuestion(false);
      return true;
    } else {
      if (userData && (questions.length === 0 || isManualGenerate)) {
        const body = {
          fk_Enterprise_ID: state?.item?.fk_Enterprise_ID,
          fk_User_ID: userData?.pk_User_ID,
          fk_Job_ID: 1,
          fk_Campaign_ID: state.item.pk_Campaign_ID,
          Question_RecruiterRating: 2,
          Question_Order: 1,
          Question_Deleted: 0,
          Question_Status: 0,
          Question_AccessLevel: 0,
        };

        dispatch(
          createAIQuestionAPI({
            number: isManualGenerate ? noOfQue : 5,
            title: state.item.Campaign_Name,
            data: body,
          })
        )
          .unwrap()
          .then((originalPromiseResult: any) => {
            setAnchorEl(null);
            setIsAIApiCallQuestion(false);
            // isAudioGeneratedNew = true;
            dispatch(fetchQuestions(state.item.pk_Campaign_ID));
            // dispatch(addQuestionReducer({item: element}))
          })
          .catch((rejectedValueOrSerializedError: any) => {
            setAnchorEl(null);
            setIsAIApiCallQuestion(false);
            console.log('getting error', rejectedValueOrSerializedError);
          });
      } else {
        setIsAIApiCallQuestion(false);
      }
    }
  };

  const [{ isOver: isOver1 }, dropQuestion] = useDrop(
    () => ({
      accept: ['tableItem'],
      drop: (item: any) => {
        if (!state) {
          toastFailure('Job Title is required.');
        }

        if (item.data[0].hasOwnProperty('Template_Name')) {
          dispatch(fetchTemplatesQuestionsById(item.data[0].pk_Template_ID))
            .unwrap()
            .then((res: any) => {
              const templateQuestions =
                res.templateQuestionResponse.data.filter(
                  (itm: { QuestionTemplate_Deleted: number }) =>
                    itm.QuestionTemplate_Deleted !== 1
                );
              const modifyQuestionData = templateQuestions.map(
                (itemValue: any) => {
                  return {
                    pk_Question_ID: Math.ceil(Math.random() * 500),
                    fk_Enterprise_ID: state.item.fk_Enterprise_ID,
                    fk_User_ID: userData?.pk_User_ID,
                    sSession: itemValue?.sSession,
                    fk_Job_ID: 1,
                    fk_Campaign_ID: state.item.pk_Campaign_ID,
                    Tironem_Question_Text: itemValue?.QuestionTemplate_Text,
                    Question_RecruiterRating:
                      itemValue.QuestionTemplate_RecruiterRating,
                    Question_Order: itemValue?.QuestionTemplate_Order,
                    Question_Deleted: itemValue?.QuestionTemplate_Deleted,
                    Question_Status: itemValue?.QuestionTemplate_Status,
                    Question_AccessLevel:
                      itemValue?.QuestionTemplate_AccessLevel,
                    Question_Record_GUID: '',
                    Question_DateOfCreation: new Date().toISOString(),
                    Question_DateOfLastUpdate: new Date().toISOString(),
                  };
                }
              );
              handleDropQuestion({ data: modifyQuestionData }, questions);
            })
            .catch((error: any) => {
              console.log(error);
            });
        } else {
          const modifyQuestionData = item.data.map((itemValue: any) => {
            return {
              pk_Question_ID: Math.ceil(Math.random() * 500),
              fk_Enterprise_ID: state.item.fk_Enterprise_ID,
              fk_User_ID: userData?.pk_User_ID,
              sSession: itemValue?.sSession,
              fk_Job_ID: 1,
              fk_Campaign_ID: state.item.pk_Campaign_ID,
              Tironem_Question_Text: itemValue?.QuestionTemplate_Text,
              Question_RecruiterRating:
                itemValue.QuestionTemplate_RecruiterRating,
              Question_Order: itemValue?.QuestionTemplate_Order,
              Question_Deleted: itemValue?.QuestionTemplate_Deleted,
              Question_Status: itemValue?.QuestionTemplate_Status,
              Question_AccessLevel: itemValue?.QuestionTemplate_AccessLevel,
              Question_Record_GUID: '',
              Question_DateOfCreation: new Date().toISOString(),
              Question_DateOfLastUpdate: new Date().toISOString(),
            };
          });
          handleDropQuestion({ data: modifyQuestionData }, questions);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [questions]
  );

  const handleAIQuestionGenerateClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const [noOfQue, setNoOfQue] = useState<any>(1);

  const handleCloseAIQuestionGenerateClick = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        alignSelf: 'flex-start',
      }}
    >
      <Box
        sx={{
          width: '75%',
          position: 'relative',
          paddingTop: '20px',
          alignSelf: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            fontSize: '20px',
            fontWeight: 500,
            fontFamily: 'Poppins',
          }}
        >
          {jobTitle}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              navigate(Urls.Dashboard);
            }}
          >
            <BackIcon />
            <Typography
              fontSize={'15px'}
              sx={{
                paddingTop: 0,
                fontWeight: 600,
                cursor: 'pointer',
                paddingX: '15px',
              }}
              color={Colors.primary}
              variant={'body1'}
            >
              Back
            </Typography>
          </Box>
          <Box sx={{}}>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'large'}
              sx={{
                width: '120px',
                height: 40,
                fontSize: '13px',
                fontWeight: 600,
              }}
              onClick={() => {
                if (!loading) {
                  handleGenerateAudio();
                }
              }}
            >
              {loading ? (
                <CircularProgress
                  style={{ width: '30px', height: '30px' }}
                  color='inherit'
                />
              ) : (
                'Save'
              )}
            </Button>
          </Box>
        </Box>

        <Box
          className={classes.containerForm}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 180px)',
            overflowY: 'scroll',
          }}
        >
          {expandState[0].state && <br />}
          {/* /////////////////////JOB DETAIL SECTION//////////////////////////////// */}
          <AccordionExpandable
            expanded={expandState[0].state}
            onExpandAccordion={() => handleAccordionState(1)}
            title={'Job Detail'}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100vh - 180px)',
                overflowY: 'scroll',
                width: '100%',
                '::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              {/* Job Title and Select Company */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  // justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  gap: '8px',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                  }}
                >
                  <Typography
                    sx={{ marginTop: '15px' }}
                    color={Colors.black}
                    fontSize={'14px'}
                    variant='h6'
                  >
                    Company
                  </Typography>
                  <Box
                    sx={{
                      cursor: 'pointer',
                      pointerEvents: 'auto',
                      width: '100%',
                    }}
                    onClick={() => {
                      setEmployerChangeModal(true);
                    }}
                  >
                    <CandidateList
                      dropdown
                      isEdit={false}
                      pageType={'create'}
                      candidateData={selectedCompany}
                      isJobCreate={true}
                    />
                  </Box>
                </Box>
              </div>

              {/* Campaign Reference Code and Date */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'calc(100vh - 180px)',
                  width: '100%',
                  '::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    // justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '10px',
                    marginLeft: 15,
                  }}
                >
                  <Box sx={{ width: '30%' }}>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography
                        sx={{ marginTop: '15px' }}
                        color={Colors.black}
                        fontSize={'14px'}
                        variant='h6'
                      >
                        Job Title
                      </Typography>
                      <Box>
                        <Box
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Box
                            style={{
                              height: '60px',
                              borderRadius: '8px',
                              backgroundColor: Colors.background_grey,
                            }}
                          >
                            <Input
                              placeholder='Write the job Title of the position you wish to fill:'
                              value={jobTitle}
                              onChange={(e: any) => setJobTitle(e.target.value)}
                              sx={{
                                border: 'none',
                                fontSize: '13px',
                                paddingY: '0px',
                                backgroundColor: Colors.background_grey,
                              }}
                            />
                            <Box
                              sx={{
                                height: '1px',
                                background: Colors.lavender_blue,
                                marginX: '10px',
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: '15%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      sx={{ marginTop: '15px' }}
                      color={Colors.black}
                      fontSize={'14px'}
                      variant='h6'
                    >
                      Job Code
                    </Typography>
                    <Box
                      style={{
                        height: '60px',
                        borderRadius: '8px',
                        backgroundColor: Colors.background_grey,
                      }}
                    >
                      <Input
                        placeholder='Please fill Reference Code'
                        value={campaignReferenceCode}
                        onChange={(e: any) =>
                          setCampaignReferenceCode(e.target.value)
                        }
                        sx={{
                          border: 'none',
                          fontSize: '13px',
                          paddingBottom: '0px',
                          backgroundColor: Colors.background_grey,
                        }}
                      />
                      <Box
                        sx={{
                          height: '1px',
                          background: Colors.lavender_blue,
                          marginX: '10px',
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: '10%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      sx={{ marginTop: '15px' }}
                      color={Colors.black}
                      fontSize={'14px'}
                      variant='h6'
                    >
                      Job ID
                    </Typography>
                    <Box
                      style={{
                        height: '60px',
                        borderRadius: '8px',
                        backgroundColor: Colors.background_grey,
                      }}
                    >
                      <Input
                        placeholder='Please fill Reference Code'
                        // value={campaignReferenceCode}
                        value={state.item.pk_Campaign_ID}
                        // onChange={(e: any) =>
                        //   setCampaignReferenceCode(e.target.value)
                        // }
                        sx={{
                          border: 'none',
                          fontSize: '13px',
                          paddingBottom: '0px',
                          backgroundColor: Colors.background_grey,
                        }}
                        disabled
                      />
                      <Box
                        sx={{
                          height: '1px',
                          background: Colors.lavender_blue,
                          marginX: '10px',
                        }}
                      />
                    </Box>
                  </Box>
                  {/* DATE PICKER */}
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      // marginTop: '10px',
                    }}
                  >
                    <DateSelectorNew
                      isEdit={true}
                      pageType={'create'}
                      commencementDate={(date) =>
                        setCampaignCommencementDate(formatDateToString(date))
                      }
                      completionDate={(date) =>
                        setCampaignCompletionDate(formatDateToString(date))
                      }
                      setEndDate={setEndDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      startDate={startDate}
                    />
                    <div
                      style={{
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 20,
                      }}
                    >
                      <IconButton
                        size={'small'}
                        sx={{
                          color: Colors.primary,
                          textTransform: 'uppercase',
                        }}
                        onClick={() => setHiringManagerChangeModal(true)}
                      >
                        <Box
                          sx={{
                            height: '46px',
                            width: '46px',
                            borderRadius: '50% !important',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 600,
                            fontSize: '14px',
                            backgroundColor: 'red',
                            background:
                              selectedEmployer?.employerBgColor ||
                              Colors.Maximum_blue_purple,
                            color:
                              selectedEmployer?.employerColor &&
                              selectedEmployer?.employerColor.includes('#')
                                ? selectedEmployer?.employerColor
                                : Colors.white,
                          }}
                        >
                          {selectedEmployer?.employerPic !== '' ? (
                            <img
                              src={selectedEmployer?.employerPic}
                              alt='User_profile'
                              style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                objectFit: 'cover',
                              }}
                            />
                          ) : (
                            selectedEmployer?.employerInitials ??
                            selectedEmployer?.employerInitials
                          )}
                        </Box>
                      </IconButton>

                      {selectedEmployer?.employerName}
                    </div>
                    {/* <div
            style={{
              display: 'flex',
              gap: '10px',
              // marginTop: '10px',
            }}
          >
            <h1 >
              {selectedEmployer.employerName}
              
            </h1>
          </div> */}
                  </div>
                </div>
              </Box>
              {/* Job Category */}
              <Box
                sx={{
                  marginTop: '15px',
                  padding: '20px',
                  borderRadius: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                {/* <Typography
      sx={{ width: '100%' }}
      color={Colors.primary}
      fontSize={'14px'}
      variant='h6'
    >
      Job Category
    </Typography> */}
                <Box sx={{ display: 'flex', width: '100%', gap: '15px' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#464646',
                        paddingBottom: '5px',
                        paddingTop: '10px',
                      }}
                    >
                      Main Category
                    </Typography>
                    <SelectboxforJobCategory
                      handleClick={(e: React.MouseEvent<HTMLDivElement>) => {
                        handleClickMainCategory(e, 'MainCategory');
                      }}
                      handleClose={() => {
                        handleCloseMainCategory('MainCategory');
                      }}
                      changeBoxValue={(val, id) => {
                        setSelectionBoxValue(val, 'MainCategory', id);
                      }}
                      open={true}
                      anchorEl={anchorElOpenMainCategory}
                      isCreatePage={true}
                      title={mainJobCategoryValue || 'Main Category'}
                      menuOption={mainCategoryArr}
                      name='MainCategory'
                    />
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#464646',
                        paddingBottom: '5px',
                        paddingTop: '10px',
                      }}
                    >
                      Sub Category
                    </Typography>
                    <SelectboxforJobCategory
                      handleClick={(e: React.MouseEvent<HTMLDivElement>) => {
                        handleClickMainCategory(e, 'SubCategory');
                      }}
                      handleClose={() => {
                        handleCloseMainCategory('SubCategory');
                      }}
                      changeBoxValue={(val, id) => {
                        setSelectionBoxValue(val, 'SubCategory', id);
                      }}
                      open={true}
                      anchorEl={anchorElOpenSubCategory}
                      isCreatePage={true}
                      title={subJobCategoryValue || 'Sub Category'}
                      menuOption={subCategoryArr}
                      name='SubCategory'
                    />
                  </Box>
                </Box>
              </Box>

              {/* Location */}
              <Box
                sx={{
                  // background: Colors.white,
                  // marginTop: '15px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  borderRadius: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                {/* <Typography
      sx={{ width: '100%' }}
      color={Colors.primary}
      fontSize={'14px'}
      variant='h6'
    >
      Location
    </Typography> */}

                <Box sx={{ display: 'flex', width: '100%', gap: '15px' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#464646',
                        paddingBottom: '5px',
                        // paddingTop: '10px',
                      }}
                    >
                      Country
                    </Typography>
                    {/* <PhoneInput
                  international
                  sx={{
                    border: `1px solid ${Colors.default}`,
                    height: "50px",
                    borderRadius: "6px",
                    padding: "0px 10px",
                  }}
                  defaultCountry={"AU"}
                  countryCallingCodeEditable={false}
                  value={""}
                  onChange={(value) => {}}
                /> */}
                    <ReactFlagsSelect
                      countries={['AU']}
                      selected={'AU'}
                      onSelect={(code) => setSelected(code)}
                    />
                  </Box>
                  {/* <Box sx={{ width: '33%' }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '18px',
            color: '#464646',
            paddingBottom: '5px',
            paddingTop: '10px',
          }}
        >
          Region
        </Typography>
        <Selectbox
                  handleClick={(
                    e: React.MouseEvent<HTMLDivElement>
                  ) => {
                    handleClickMainCategory(e, 'Region');
                  }}
                  handleClose={() => {
                    handleCloseMainCategory('Region');
                  }}
                  changeBoxValue={(val) => {}}
                  open={true}
                  anchorEl={anchorElOpenRegion}
                  isCreatePage={true}
                  title="Sydney NSW"
                  menuOption={['NSW', 'WA', 'QLD', 'ACT']}
                />
        <TextInput
          type={'text'}
          maxWidth='100%'
          height='47px'
          border='1px solid #D0D0D0'
          placeholder={'Enter region'}
          value={regionValue || ''}
          padding={'0px 10px'}
          onChange={(value) => {
            setRegionValue(value);
          }}
        />
      </Box> */}
                  <Box sx={{ width: '50%' }}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#464646',
                        paddingBottom: '5px',
                      }}
                      ref={workTypeRef}
                    >
                      City
                    </Typography>

                    <Autocomplete
                      sx={{
                        borderRadius: '10px',

                        '& .MuiInputBase-root': {
                          height: 47,
                          borderRadius: '10px',
                          paddingTop: '2px',
                          borderColor: '#D0D0D0 !important',
                          backgroundColor: Colors.background_grey,
                        },
                      }}
                      onChange={(event, selection) => {
                        setCityValue(selection);
                        setSelectedCityValue('');
                      }}
                      value={
                        selectedCityValue
                          ? {
                              label: subLocations.filter(
                                (sb) => sb.value === selectedCityValue
                              )[0]?.label,
                              value: subLocations.filter(
                                (sb) => sb.value === selectedCityValue
                              )[0]?.value,
                            }
                          : cityValue
                      }
                      options={subLocations}
                      disableClearable
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <Box component='li' {...props}>
                          {option.label}
                        </Box>
                      )}
                      filterOptions={filterOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            type: 'search',
                          }}
                          placeholder='Choose city'
                          sx={{
                            borderColor: '#D0D0D0',
                            borderRadius: '10px',
                            '& .MuiInputBase-root': {
                              borderColor: '#D0D0D0',
                              height: 47,
                              borderRadius: '10px',
                              paddingTop: '2px',
                              color: '#919191',
                            },
                            '& .MuiAutocomplete-inputFocused': {
                              borderColor: '#D0D0D0',
                              color: '#626161',
                            },
                          }}
                        />
                      )}
                    />
                    {/* <GooglePlacesInput
                      setCityValue={(selection) =>
                        setCityValue({
                          label: selection,
                          value: selection,
                        })
                      }
                      countryCode={selected}
                      selectedCityValue={cityValue?.value}
                    /> */}
                  </Box>
                </Box>
              </Box>

              {/* Work Type and Job Pay Type */}
              <Box
                sx={{
                  marginTop: '8px',
                  borderRadius: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  paddingX: '20px',
                }}
              >
                <Box sx={{ width: '49%' }}>
                  <Typography
                    sx={{ width: '100%', paddingBottom: '5px' }}
                    color={Colors.black}
                    fontSize={'14px'}
                    variant='h6'
                  >
                    Work Type
                  </Typography>
                  <Selectbox
                    handleClick={(e: React.MouseEvent<HTMLDivElement>) => {
                      handleClickMainCategory(e, 'WorkType');
                    }}
                    handleClose={() => {
                      handleCloseMainCategory('WorkType');
                    }}
                    changeBoxValue={(val) => {
                      setSelectionBoxValue(val, 'WorkType');
                    }}
                    open={true}
                    anchorEl={anchorElOpenWorkType}
                    isCreatePage={true}
                    // isNotBorder={true}
                    title={workType || 'WorkType'}
                    defaultValue={workType}
                    menuOption={[
                      'Full-time',
                      'Part-time',
                      'Contract',
                      'Casual',
                    ]}
                  />
                </Box>
                <Box sx={{ width: '49%' }}>
                  <Typography
                    sx={{ width: '100%', paddingBottom: '5px' }}
                    color={Colors.black}
                    fontSize={'14px'}
                    variant='h6'
                  >
                    Pay Type
                  </Typography>
                  <Selectbox
                    handleClick={(e: React.MouseEvent<HTMLDivElement>) => {
                      handleClickMainCategory(e, 'PayId');
                    }}
                    handleClose={() => {
                      handleCloseMainCategory('PayId');
                    }}
                    changeBoxValue={(val) => {
                      setSelectionBoxValue(val, 'PayId');
                    }}
                    open={true}
                    anchorEl={anchorElOpenPayDetails}
                    isCreatePage={true}
                    // isNotBorder={true}
                    title={payType || 'PayType'}
                    defaultValue={payType}
                    menuOption={[
                      'Hourly rate',
                      'Annual salary',
                      'Annual pluscommission',
                    ]}
                  />
                </Box>
              </Box>

              {/* Job Pay Range */}
              <Box
                sx={{
                  // background: Colors.white,
                  marginTop: '15px',
                  padding: '20px',
                  borderRadius: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  marginBottom: '20px',
                }}
                // ref={AIMenu}
              >
                <Typography
                  sx={{ width: '100%' }}
                  color={Colors.black}
                  fontSize={'14px'}
                  variant='h6'
                >
                  Job Pay Range
                </Typography>
                <Typography
                  sx={{
                    width: '100%',
                    fontWeight: 400,
                    fontSize: '12px',
                    color: '#9D9D9D',
                  }}
                  variant='h6'
                >
                  Enter range to offer candidates. If pay is an exact amount,
                  enter the same in both fields.
                </Typography>
                <Box sx={{ display: 'flex', width: '100%', gap: '15px' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#464646',
                        paddingBottom: '5px',
                        paddingTop: '10px',
                      }}
                    >
                      From
                    </Typography>
                    <TextInput
                      type={'number'}
                      maxWidth='100%'
                      height='47px'
                      border='1px solid #D0D0D0'
                      placeholder={'Enter minimum pay'}
                      isIcopn={true}
                      leftIcon
                      value={fromValue || ''}
                      padding={'0px 10px'}
                      onChange={(value) => {
                        setFromValue(value);
                      }}
                    />
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#464646',
                        paddingBottom: '5px',
                        paddingTop: '10px',
                      }}
                    >
                      To
                    </Typography>
                    <TextInput
                      type={'number'}
                      maxWidth='100%'
                      height='47px'
                      border='1px solid #D0D0D0'
                      placeholder={'Enter maximum pay'}
                      isIcopn={true}
                      leftIcon
                      value={toValue || ''}
                      padding={'0px 10px'}
                      onChange={(value) => {
                        setTovalue(value);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </AccordionExpandable>

          {expandState[0].state && <br />}
          {/* /////////////////////INTERVIEW QUESTIONS SECTION//////////////////////////////// */}
          <AccordionExpandable
            expanded={expandState[1].state}
            onExpandAccordion={() => handleAccordionState(2)}
            title={'Interview Questions'}
          >
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ width: '60%' }}>
                <Card
                  sx={{
                    marginY: '20px',
                    borderRadius: '0px',
                    backgroundColor: Colors.white,
                    overflow: 'visible',
                  }}
                  elevation={0}
                >
                  <CardContent sx={{ paddingBottom: '0px' }}>
                    <CardHeader
                      pageType={'create'}
                      title={'Questions'}
                      headerColor={Colors.black}
                      state={state}
                      extraButton={() => {
                        return (
                          <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <Button
                              variant={'text'}
                              onClick={() => {
                                setAddNewQuestion(true);
                              }}
                              sx={{
                                color: Colors.primary,
                                // alignSelf: 'flex-end',
                                fontSize: '14px',
                                padding: 0,
                                height: 'auto',
                                fontWeight: 500,
                                marginRight: 2,
                              }}
                            >
                              <Add
                                sx={{
                                  color: Colors.primary,
                                  fontSize: 22,
                                }}
                              />
                              Question
                            </Button>

                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              ref={QuestionRef}
                            >
                              <IconButton
                                size={'small'}
                                sx={{ color: 'transparent' }}
                                onClick={handleAIQuestionGenerateClick}
                                aria-controls={
                                  openAnchor ? 'account-menu' : undefined
                                }
                                aria-haspopup='true'
                                aria-expanded={openAnchor ? 'true' : undefined}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    marginRight: '16px',
                                  }}
                                >
                                  <AIBlueIcon
                                    style={{
                                      marginRight: '1px',
                                      width: '25px',
                                      height: '15px',
                                    }}
                                  />
                                  <Typography
                                    color={Colors.primary}
                                    fontSize={'12px'}
                                    variant='h6'
                                  >
                                    Generate Question
                                  </Typography>
                                  <KeyboardArrowDownIcon
                                    sx={{
                                      color: Colors.primary,
                                      fontSize: 15,
                                    }}
                                  />
                                </div>
                              </IconButton>
                              {/* NOTE GENERATE QUE MENU */}
                              <Menu
                                open={openAnchor}
                                transformOrigin={{
                                  horizontal: 'right',
                                  vertical: 'top',
                                }}
                                anchorOrigin={{
                                  horizontal: 'right',
                                  vertical: 'bottom',
                                }}
                                PaperProps={{
                                  sx: {
                                    width: '250px',
                                    borderRadius: '5px',
                                    boxShadow:
                                      '0px 4px 20px rgba(0, 0, 0, 0.08)',
                                  },
                                }}
                                onClose={handleCloseAIQuestionGenerateClick}
                                anchorEl={anchorEl}
                                id='user-detail-menu'
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    padding: '10px',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      marginTop: '30px',
                                      marginBottom: '4px',
                                    }}
                                  >
                                    <SliderInput
                                      sliderWidth='100%'
                                      labeldefault='on'
                                      max={10}
                                      value={noOfQue}
                                      onChange={(value) => {
                                        setNoOfQue(value);
                                      }}
                                    />
                                  </Box>

                                  {isAIApiCallQuestion ? (
                                    <Button
                                      variant={'contained'}
                                      color={'primary'}
                                      size={'large'}
                                      sx={{
                                        width: '100%',
                                        height: 40,
                                        fontSize: '13px',
                                        fontWeight: 600,
                                      }}
                                    >
                                      <CircularProgress
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                        }}
                                        color='inherit'
                                      />
                                    </Button>
                                  ) : (
                                    <Button
                                      variant={'contained'}
                                      color={'primary'}
                                      size={'large'}
                                      sx={{
                                        width: '100%',
                                        height: 40,
                                        fontSize: '13px',
                                        fontWeight: 600,
                                      }}
                                      onClick={() => {
                                        handleGPTGenerateQuestions(true);
                                        forceUpdate();
                                      }}
                                    >
                                      Generate
                                    </Button>
                                  )}
                                </Box>
                              </Menu>
                            </div>
                          </div>
                        );
                      }}
                    />
                    <Box
                      sx={{
                        backgroundColor: Colors.white,
                        display: 'flex',
                        justifyContent: 'center',
                        height: 80,
                        borderRadius: 2,
                        alignItems: 'center',
                        color: Colors.gray1,
                        border: `1px dotted ${Colors.gray_A9}`,
                        marginTop: '10px',
                      }}
                      ref={dropQuestion}
                    >
                      <Typography
                        fontSize={'12px'}
                        fontWeight={600}
                        color={Colors.gray_97}
                        variant={'body1'}
                      >
                        Drop Question from templates here
                      </Typography>
                    </Box>
                    <List component='nav' aria-label='mailbox folders'>
                      {addNewQuestion && (
                        <div
                          style={{
                            borderRadius: '4px',
                            margin: '10px 0',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              height: 45,
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              margin: '10px 0px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: 'calc(100% - 120px)',
                              }}
                            >
                              <TextInput
                                value={newQuestion}
                                onChange={(value) => {
                                  setNewQuestion(value);
                                }}
                                border={`1px solid ${Colors.default}`}
                                placeholder={'Please fill in question'}
                                width={100}
                              />
                            </div>
                            <div style={{ width: '90px' }}>
                              <IconButton
                                edge='end'
                                aria-label='edit'
                                onClick={() => {
                                  if (newQuestion.trim() === '') {
                                    toastFailure('Please enter a question');
                                    return;
                                  }
                                  hanldeAddNewQuestion();
                                }}
                              >
                                <Check
                                  sx={{
                                    color: Colors.primary,
                                    fontSize: 30,
                                  }}
                                />
                              </IconButton>
                              <IconButton
                                edge='end'
                                aria-label='edit'
                                onClick={() => {
                                  setAddNewQuestion(false);
                                  setNewQuestion('');
                                }}
                              >
                                <Close
                                  sx={{
                                    color: Colors.secondary,
                                    fontSize: 30,
                                  }}
                                />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      )}
                      {isAIApiCallQuestion && (
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            paddingTop: '5%',
                            height: '315px',
                          }}
                        >
                          <CircleLoader color='#AA19D5' />
                        </Box>
                      )}
                      {!isAIApiCallQuestion &&
                        itemsQuestion?.length > 0 &&
                        itemsQuestion
                          .filter(
                            (item: Questions) => item.Question_Deleted === 0
                          )
                          .map((item: Questions, index: any) => {
                            return (
                              <Fragment key={index}>
                                <CommonLists
                                  index={index}
                                  pageType={'create'}
                                  onDelete={handleQuestionRemove}
                                  onUpdate={handleQuestionUpdate}
                                  setPlayAudioIndex={setPlayAudioIndex}
                                  playAudioIndex={playAudioIndex}
                                  item={item}
                                  key={index}
                                  type='question'
                                  isQuestion='ForDragAndDrop'
                                  listtext={
                                    item.Tironem_Question_Text.length > 0
                                      ? item.Tironem_Question_Text
                                      : item.ChatGTP_Question_Text
                                  }
                                  id={item.pk_Question_ID}
                                  moveItem={moveItem}
                                />
                              </Fragment>
                            );
                          })}
                    </List>
                  </CardContent>
                </Card>
              </Box>
              <Box sx={{ width: '38%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    margin: 1,
                    marginTop: 2.5,
                  }}
                >
                  <DrawerRightNew
                    isCreateCampaign={true}
                    selectedCompany={selectedCompany}
                    isQuestionTemplate={true}
                    labelColor={Colors.black}
                    isInstantJob={true}
                  />
                </Box>
              </Box>
            </Box>
          </AccordionExpandable>

          {expandState[1].state && <br />}
          {/* /////////////////////JOB DESCRIPTION SECTION//////////////////////////////// */}
          <AccordionExpandable
            expanded={expandState[2].state}
            onExpandAccordion={() => handleAccordionState(3)}
            title={'Job Description'}
          >
            <JobDescriptionForm
              pageType={'create'}
              campaignJobTitle={jobTitle}
              onUpdateJobDescription={onUpdateJDContent}
            />
          </AccordionExpandable>
          <br />
          <Box ref={CommentsRef} sx={{ padding: 0 }}>
            <Card
              sx={{
                marginY: '0px',
                borderRadius: '6px',
                background: Colors.white,
              }}
              elevation={0}
            >
              <CardContent>
                <List>
                  {addNewComment && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-evenly',
                        }}
                      >
                        <TextInput
                          isComment={true}
                          iscallApiByEnter={iscallApiByEnter}
                          setIscallApiByEnter={setIscallApiByEnter}
                          placeholder={'Add a comment '}
                          value={newComment}
                          onChange={(value) => setNewComment(value)}
                          width={85}
                          border={`1px solid ${Colors.default}`}
                        />

                        <IconButton
                          edge='end'
                          onClick={() => {
                            handleAddNewComment();
                          }}
                          aria-label='edit'
                        >
                          <Check
                            sx={{
                              color: Colors.primary,
                              fontSize: 30,
                            }}
                          />
                        </IconButton>
                        <IconButton
                          edge='end'
                          onClick={() => {
                            setAddNewComment(false);
                            setNewComment('');
                          }}
                          aria-label='edit'
                        >
                          <Close
                            sx={{
                              color: Colors.secondary,
                              fontSize: 30,
                            }}
                          />
                        </IconButton>
                      </div>
                    </>
                  )}
                </List>
                {campaignComments.map((item: any, index) => {
                  return (
                    <Fragment key={index}>
                      <CommonLists
                        isComment={true}
                        pageType={'create'}
                        onDeleteComment={handleCommentRemove}
                        onUpdate={handleCommentUpdate}
                        type='comment'
                        item={item}
                        listtext={item.Comment_Content}
                        id={item.pk_Comment_ID}
                        userListData={userListData}
                        setMentionsData={setMentionsData}
                      />
                    </Fragment>
                  );
                })}

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 120,
                  }}
                >
                  <Button
                    variant={'text'}
                    sx={{
                      color: Colors.primary,
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                    onClick={() => {
                      if (!state) {
                        toastFailure('Please enter campaign title!');
                      } else {
                        if (setAddNewComment) {
                          setAddNewComment(true);
                        }
                      }
                    }}
                  >
                    <AddIcon
                      sx={{
                        color: Colors.primary,
                        fontSize: 20,
                      }}
                    />
                    Add Comment
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Box>
      {/* ////////////////////////////////////////////////////////////////////////////////// */}

      <Dialog
        open={isHiringManagerChangeModal}
        onClose={() => setHiringManagerChangeModal(false)}
      >
        <DialogTitle
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            textAlign: 'center',
          }}
        >
          Select a hiring Manager
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '0px' }}>
          <TeamComp
            teamInfo={usersByEnterpriseId}
            userInfo={adminUser}
            userData={userData}
            enterpriseData={enterpriseData}
            isCreateEditJob={true}
            onSelectEmployer={(item) => {
              setSelectedEmployer({
                employerName: item?.User_FirstName?.substring(0, 8),
                employerId: item?.pk_User_ID,
                employerPic: item?.User_ImageLink ?? '',
                employerInitials: item?.User_Initials,
                employerBgColor: item?.User_BackgroundColour,
                employerColor: item?.User_Colour,
              });
              setHiringManagerChangeModal(false);
            }}
            currentEmployer={selectedEmployer.employerId}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isEmployerChangeModal}
        onClose={() => setEmployerChangeModal(false)}
      >
        <DialogTitle
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            textAlign: 'center',
          }}
        >
          Select an employer
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '0px' }}>
          {allEmployers.length > 0 &&
            allEmployers?.map((i) => (
              <>
                <div
                  style={{
                    paddingLeft: '0px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '50%',
                    margin: 10,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleUpdateEmployerSelection(i)}
                >
                  {i?.Enterprise_ImageLink || i?.Employer_ImageLink ? (
                    <img
                      style={{
                        height: 50,
                        width: 50,
                        borderRadius: 120,
                        objectFit: 'cover',
                      }}
                      src={i?.Enterprise_ImageLink ?? i?.Employer_ImageLink}
                      alt='logo'
                    />
                  ) : (
                    <div
                      style={{
                        backgroundColor: `${
                          i?.Enterprise_BackgroundColour
                            ? i?.Enterprise_BackgroundColour
                            : i?.Employer_BackgroundColour
                            ? i?.Employer_BackgroundColour
                            : Colors.Maximum_blue_purple
                        }`,
                        height: 50,
                        width: 100,
                        borderRadius: 100,
                        marginRight: '6px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textTransform: 'uppercase',
                        fontSize: '18px',
                        fontWeight: 500,
                        color: `${
                          i?.Enterprise_Colour
                            ? i?.Enterprise_Colour
                            : i?.Employer_Colour
                            ? i?.Employer_Colour
                            : Colors.black
                        }`,
                      }}
                    >
                      <div>
                        {i?.Enterprise_Initials
                          ? i?.Enterprise_Initials
                          : i?.Employer_Initials
                          ? i?.Employer_Initials
                          : i?.Employer_Name
                          ? i?.Employer_Name?.substring(0, 1)
                          : i?.Enterprise_Name?.substring(0, 1)}
                      </div>
                    </div>
                  )}
                  <Typography
                    sx={{
                      padding: '0',
                      color: Colors.outerSpace,
                      width: 280,
                      marginLeft: 2,
                    }}
                    fontSize='14px'
                  >
                    {i?.Employer_Name ?? i?.Enterprise_Name}
                  </Typography>
                </div>
              </>
            ))}
        </DialogContent>
      </Dialog>

      <Dialog
        open={isJobCreatedModal}
        onClose={() => setJobCreatedModal(false)}
      >
        <DialogTitle
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            textAlign: 'center',
          }}
        >
          Job Created
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '0px', textAlign: 'center' }}>
          <p>
            Your job interview process is currently being built. It will be
            ready, in approximately 5 minutes.
          </p>
        </DialogContent>
        <Button
          variant={'contained'}
          color={'primary'}
          size={'large'}
          sx={{
            width: '120px',
            height: 40,
            fontSize: '13px',
            fontWeight: 600,
            alignSelf: 'center',
            margin: 2,
          }}
          onClick={() => {
            setJobCreatedModal(false);

            setTimeout(() => {
              navigate(Urls.Campaign);
            }, 100);
          }}
        >
          Close
        </Button>
      </Dialog>
    </Box>
  );
};

export default CampaignForm;
