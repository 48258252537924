import { Button, CircularProgress, Typography, colors } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Colors } from '../../theme/colors';
import AuthBackgroundImage from '../../images/background/authbackground.png';
import TIRONEM_LOGO from '../../images/TIRONEM_LOGO.png';
import CssTextField from '../../components/Utils/TextField/CssTextField';
import { toastFailure, toastSuccess } from '../../utils/toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../redux/store';
import {
  SendOTP,
  SetNewPassword,
  VerifyOTP,
} from '../../redux/user/user-slice';
import OtpInput from 'react-otp-input';
import successImage from '../../images/success.svg';
const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState<string>('');
  const [isCallApi, setIsCallApi] = useState<boolean>(false);
  const [isSentOTP, setisSentOTP] = useState<boolean>(false);
  const [isOTPVerified, setisOTPVerified] = useState<boolean>(false);
  const [otp, setOtp] = useState('');

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isSuccessfullyChangePassword, setIsSuccessfullyChangePassword] =
    useState<boolean>(false);

  const [counter, setCounter] = React.useState(0);

  const onSendOTP = () => {
    setIsCallApi(true);
    let message: string;
    if (email.toString().trim() == '') {
      setIsCallApi(false);
      message = 'Please enter email';
      toastFailure(message);
    } else if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email) === false) {
      setIsCallApi(false);
      message = 'email format is not valid';
      toastFailure(message);
    } else {
      dispatch(SendOTP(email.toString()))
        .unwrap()
        .then(
          (data: any) => {
            if (data?.response?.status === 201 && data?.response?.successful) {
              toastSuccess(data.response.data);
              setIsCallApi(false);
              setisSentOTP(true);
              setCounter(30);
            } else {
              setIsCallApi(false);
              toastFailure('User not found');
            }
          },
          (err: any) => {
            setIsCallApi(false);
            toastFailure('Something went wrong');
          }
        );
    }
  };

  const onVerifyOTP = () => {
    setIsCallApi(true);
    dispatch(VerifyOTP(otp))
      .unwrap()
      .then(
        (data: any) => {
          console.log('Response : ', data);
          if (data?.response?.status === 200 && data?.response?.successful) {
            toastSuccess(data.response.data);
            setIsCallApi(false);
            setisOTPVerified(true);
            setCounter(0);
          } else {
            setIsCallApi(false);
            setCounter(0);
            toastFailure('Failed to verify OTP.');
          }
        },
        (err: any) => {
          setIsCallApi(false);
          setCounter(0);
          toastFailure('Failed to verify OTP.');
        }
      );
  };

  const onChangePassword = () => {
    setIsCallApi(true);
    const payload = {
      Email: email,
      Password: password,
      ConfirmPassword: confirmPassword,
    };

    let message: string;
    if (password !== confirmPassword) {
      setIsCallApi(false);
      message = 'password not matched';
      toastFailure(message);
    } else if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])[A-Za-z\d\W_]{8,}$/.test(
        password
      ) === false
    ) {
      setIsCallApi(false);
      message =
        'Password must contain at least one small and capital letter , special characters  and numbers.';
      toastFailure(message);
    } else {
      dispatch(SetNewPassword(payload))
        .unwrap()
        .then(
          (data: any) => {
            if (data?.response?.status === 200 && data?.response?.successful) {
              toastSuccess('Reset password successful!');
              setIsSuccessfullyChangePassword(true);
              setIsCallApi(false);
            } else {
              setIsCallApi(false);
              toastFailure('Failed to change password.');
            }
          },
          (err: any) => {
            setIsCallApi(false);
            toastFailure('Failed to change password.');
          }
        );
    }
  };

  const onLoginScreen = () => {
    navigate('/login');
  };

  useEffect(() => {
    let interval: any;

    if (counter > 0) {
      interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [counter]);

  const formattedSeconds = formatSeconds(counter);

  function formatSeconds(seconds: any) {
    return String(seconds).padStart(2, '0');
  }

  return (
    <div
      style={{
        position: 'relative',
        background: 'white',
        height: '100vh',
        // width: "100vw",
        // minHeight: "750px",
        overflow: 'hidden',
      }}
    >
      <img
        src={AuthBackgroundImage}
        alt='AuthBackground_Image'
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
        }}
      />
      <div
        style={{
          position: 'relative',
          zIndex: 10,
        }}
      >
        {/* header */}
        <div
          style={{
            marginTop: '65px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={TIRONEM_LOGO}
            alt='TIRONEM_logo'
            style={{
              width: '216px',
              height: '49px',
            }}
          />
        </div>

        {/* body content */}
        <div
          style={{
            marginTop: '168px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {!isSentOTP && !isOTPVerified && !isSuccessfullyChangePassword ? (
            <>
              <Typography variant={'h4'} color={Colors.black}>
                Forgot Password
              </Typography>
              <div
                style={{
                  marginTop: '40px',
                  width: '100%',
                  maxWidth: '414px',
                }}
              >
                <CssTextField
                  id='outlined-basic'
                  variant='outlined'
                  value={email}
                  placeholder='Enter your email'
                  onChange={(e: any) => setEmail(e.target.value)}
                />
              </div>

              <div
                style={{
                  marginTop: '20px',
                  width: '100%',
                  maxWidth: '414px',
                }}
              >
                {isCallApi ? (
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    sx={{
                      width: '100%',
                      fontSize: 20,
                      height: 50,
                    }}
                  >
                    <CircularProgress
                      style={{ width: '30px', height: '30px' }}
                      color='inherit'
                    />
                  </Button>
                ) : (
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    sx={{
                      width: '100%',
                      fontSize: 20,
                      height: 50,
                    }}
                    onClick={() => onSendOTP()}
                  >
                    Send
                  </Button>
                )}
              </div>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#6F6F6F',
                  marginTop: '20px',
                  cursor: 'pointer',
                }}
                onClick={() => onLoginScreen()}
              >
                Back to Login
              </Typography>
            </>
          ) : (
            <>
              {isSentOTP && !isOTPVerified && !isSuccessfullyChangePassword ? (
                <>
                  <Typography variant={'h4'} color={Colors.black}>
                    Enter code
                  </Typography>
                  <div
                    style={{
                      width: '100%',
                      maxWidth: '414px',
                    }}
                  >
                    <Typography
                      variant={'h5'}
                      color={Colors.gray1}
                      sx={{
                        marginTop: '5px',
                        fontWeight: 500,
                        borderRadius: '10px',
                        textAlign: 'center',
                        fontSize: '18px',
                      }}
                    >
                      We have sent 6 Digit code to <br />
                      your email address{' '}
                      <span style={{ color: '#A51ED4' }}>{email}</span>
                    </Typography>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span> </span>}
                      inputStyle={{
                        width: '50px',
                        height: '60px',
                        margin: '0px',
                        marginRight: '15px',
                        fontSize: '18px',
                        textAlign: 'center',
                        border: '1px solid #CED4FF',
                        borderRadius: '10px',
                        outline: 'none',
                      }}
                      containerStyle={{ marginTop: '40px' }}
                      renderInput={(props) => <input {...props} />}
                    />
                    <Typography
                      sx={{
                        color: '#4285F4',
                        marginTop: '20px',
                        paddingTop: '0px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      00:{formattedSeconds} sec
                    </Typography>
                    <Typography
                      color={Colors.gray1}
                      sx={{
                        fontSize: '18px',
                        fontWeight: '500',
                        marginTop: '14px',
                        paddingTop: '0px',
                        display: 'flex',
                        justifyContent: 'center',
                        opacity: counter != 0 ? '50%' : '100%',
                        pointerEvents: counter != 0 ? 'none' : 'all',
                      }}
                    >
                      Don’t receive the OTP?&nbsp;
                      <span
                        style={{ color: '#A51ED4', cursor: 'pointer' }}
                        onClick={() => onSendOTP()}
                      >
                        {' '}
                        Resend OTP
                      </span>
                    </Typography>
                    <div
                      style={{
                        marginTop: '20px',
                        width: '100%',
                        maxWidth: '414px',
                      }}
                    >
                      {isCallApi ? (
                        <Button
                          variant={'contained'}
                          color={'primary'}
                          sx={{
                            width: '100%',
                            fontSize: 20,
                            height: 50,
                          }}
                        >
                          <CircularProgress
                            style={{ width: '30px', height: '30px' }}
                            color='inherit'
                          />
                        </Button>
                      ) : (
                        <Button
                          variant={'contained'}
                          color={'primary'}
                          sx={{
                            width: '100%',
                            fontSize: 20,
                            height: 50,
                          }}
                          disabled={otp.toString().length != 6}
                          onClick={() => onVerifyOTP()}
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {!isSuccessfullyChangePassword ? (
                    <>
                      <Typography variant={'h4'} color={Colors.black}>
                        Reset Password
                      </Typography>
                      <div
                        style={{
                          width: '100%',
                          maxWidth: '414px',
                          marginTop: '40px',
                        }}
                      >
                        <CssTextField
                          id='outlined-basic'
                          type='password'
                          value={password}
                          variant='outlined'
                          placeholder='Password'
                          onChange={(e: any) => setPassword(e.target.value)}
                          disabled={isCallApi}
                        />
                      </div>
                      <div
                        style={{
                          marginTop: '10px',
                          width: '100%',
                          maxWidth: '414px',
                        }}
                      >
                        <CssTextField
                          id='outlined-basic'
                          type='password'
                          value={confirmPassword}
                          variant='outlined'
                          placeholder='Confirm password'
                          onChange={(e: any) =>
                            setConfirmPassword(e.target.value)
                          }
                          disabled={isCallApi}
                        />
                      </div>
                      <div
                        style={{
                          marginTop: '20px',
                          width: '100%',
                          maxWidth: '414px',
                        }}
                      >
                        {isCallApi ? (
                          <Button
                            variant={'contained'}
                            color={'primary'}
                            sx={{
                              width: '100%',
                              fontSize: 20,
                              height: 50,
                            }}
                          >
                            <CircularProgress
                              style={{ width: '30px', height: '30px' }}
                              color='inherit'
                            />
                          </Button>
                        ) : (
                          <Button
                            variant={'contained'}
                            color={'primary'}
                            sx={{
                              width: '100%',
                              fontSize: 20,
                              height: 50,
                            }}
                            onClick={() => onChangePassword()}
                          >
                            Submit
                          </Button>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          width: '100%',
                          maxWidth: '414px',
                          marginTop: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img src={successImage} alt='React Logo' />
                      </div>
                      <Typography
                        color={Colors.black}
                        sx={{
                          fontWeight: '600',
                          fontSize: '26px',
                          marginTop: '20px',
                        }}
                      >
                        Successful
                      </Typography>

                      <Typography
                        sx={{
                          fontWeight: '400',
                          fontSize: '14px',
                          marginTop: '8px',
                          color: '#303030',
                        }}
                      >
                        Your password has been reset successfully
                      </Typography>
                      <Typography
                        color={Colors.Phlox}
                        sx={{
                          fontWeight: '400',
                          fontSize: '14px',
                          marginTop: '8px',
                          borderBottom: '1px solid #A51ED4',
                          cursor: 'pointer',
                        }}
                        onClick={() => onLoginScreen()}
                      >
                        Back to Login
                      </Typography>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
